<template>
  <ClipsAreaBase
    ref="clipsArea"
    v-model:playback-speed-factor="playbackSpeedFactor"
    :study="study"
    :clips-grid-items="clipsGridItems"
    :column-count="stageNameColumns.length"
    :row-count="viewNameRows.length"
    :focused-clip-index="focusedClipIndex"
    :is-clip-sync-enabled="isClipSyncEnabled"
    is-stress-mode-enabled
    :show-measurements="showMeasurements"
    @update:is-clip-sync-enabled="emits('update:is-clip-sync-enabled', $event)"
    @update:is-stress-mode-enabled="emits('update:is-stress-mode-enabled', $event)"
    @update:focused-clip-index="(index: number) => emits('update:focused-clip-index', index)"
    @update:show-measurements="emits('update:show-measurements', $event)"
    @scroll-to-measurement="
      (measurementId, openMeasurementPane) =>
        emits('scroll-to-measurement', measurementId, openMeasurementPane)
    "
  >
    <template #topHeader>
      <StressLayoutTableColumnHeadings
        :stages="stageNameColumns"
        :study="study"
        @add-stage="addStage"
        @remove-stage="removeStage"
        @change-stage="changeStage"
      />
    </template>

    <template #leftHeader>
      <StressLayoutTableRowHeadings
        :views="viewNameRows"
        :study="study"
        @add-view="addView"
        @remove-view="removeView"
        @change-view="changeView"
      />
    </template>

    <template #clipOverlay="{ index }">
      <StressClipDetailsOverlay
        :study="study"
        :study-clip="clipsGridItems[index].clip"
        @change-selected-clip="onChangeSelectedClip(index, $event)"
      />
    </template>
  </ClipsAreaBase>
</template>

<script setup lang="ts">
import { useElementSize, useEventListener, useStorage } from "@vueuse/core";
import { Ref, computed, ref, watch } from "vue";
import { getSHA256Hash } from "../../utils/hash-helpers";
import { Study } from "../../utils/study-data";
import { ClipsGridItem } from "../clip-viewer/clips-grid-item";
import ClipsAreaBase from "../ClipsAreaBase.vue";
import { WindowType, getWindowType } from "../multi-window/secondary-window";
import { getStressClips, getStressStageNames, getStressViewNames } from "../study-clip-helpers";
import StressClipDetailsOverlay from "./StressClipDetailsOverlay.vue";
import StressLayoutTableColumnHeadings from "./StressLayoutTableColumnHeadings.vue";
import StressLayoutTableRowHeadings from "./StressLayoutTableRowHeadings.vue";

interface Props {
  study: Study;
  clipsGridItems: ClipsGridItem[];
  selectedClipIds: string[];
  focusedClipIndex: number;
  isClipSyncEnabled: boolean;
  showMeasurements: boolean;
}

interface Emits {
  (event: "update:focused-clip-index", index: number): void;
  (event: "update:is-clip-sync-enabled", enabled: boolean): void;
  (event: "update:is-stress-mode-enabled", enabled: boolean): void;
  (event: "update:show-measurements", enabled: boolean): void;
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const playbackSpeedFactor = useStorage("stress-playback-speed-factor", 1);

const stressClips = computed(() => getStressClips(props.study));
const stageNames = computed(() => getStressStageNames(props.study));
const viewNames = computed(() => getStressViewNames(props.study));

const clipsArea = ref<HTMLDivElement | null>();
const { height: clipsAreaHeight } = useElementSize(clipsArea);

//
// Table layout persistence
//

const stageNameColumns = ref<string[]>([]);
const viewNameRows = ref<string[]>([]);

// Returns a ref for persisting this study's selected stages and views in local storage. This is
// keyed on the set of stage and view names available in the study so that studies with the same
// set of stages and views share the layout.
async function getStressTableLayoutStorage(): Promise<
  Ref<{ stageNameColumns: string[]; viewNameRows: string[] }>
> {
  const key = `stress-mode${
    getWindowType() !== WindowType.Primary ? "-secondary-window" : ""
  }-layout-${await getSHA256Hash(
    JSON.stringify({ stageNames: stageNames.value, viewNames: viewNames.value })
  )}`;

  return useStorage(key, {
    stageNameColumns: stageNames.value.slice(0, 4),
    viewNameRows: viewNames.value.slice(0, 4),
  });
}

async function loadStressTableLayout(): Promise<void> {
  const storedLayout = await getStressTableLayoutStorage();

  stageNameColumns.value = storedLayout.value.stageNameColumns;
  viewNameRows.value = storedLayout.value.viewNameRows;
}

async function saveStressTableLayout(): Promise<void> {
  const storedLayout = await getStressTableLayoutStorage();

  storedLayout.value = {
    stageNameColumns: stageNameColumns.value,
    viewNameRows: viewNameRows.value,
  };
}
watch([stageNameColumns, viewNameRows], saveStressTableLayout, { deep: true });

//
// Helpers for changing the layout of the table, e.g. adding/removing/changing stages and views.
// This is done by updating the selectedClipIds appropriately for the requested layout change.
//

function addStage(index: number, stageName: string): void {
  const selectedClipIds = props.selectedClipIds;

  stageNameColumns.value.splice(index + 1, 0, stageName);

  for (let row = 0; row < viewNameRows.value.length; row++) {
    selectedClipIds.splice(
      row * stageNameColumns.value.length + (index + 1),
      0,
      getDefaultClipForViewAndStage(viewNameRows.value[row], stageName)
    );
  }
}

function removeStage(index: number): void {
  const selectedClipIds = props.selectedClipIds;

  stageNameColumns.value.splice(index, 1);

  for (let row = 0; row < viewNameRows.value.length; row++) {
    selectedClipIds.splice(row * stageNameColumns.value.length + index, 1);
  }
}

function changeStage(index: number, stageName: string): void {
  const selectedClipIds = props.selectedClipIds;

  stageNameColumns.value[index] = stageName;

  for (let row = 0; row < viewNameRows.value.length; row++) {
    selectedClipIds[row * stageNameColumns.value.length + index] = getDefaultClipForViewAndStage(
      viewNameRows.value[row],
      stageName
    );
  }
}

function addView(index: number, viewName: string): void {
  const selectedClipIds = props.selectedClipIds;

  viewNameRows.value.splice(index + 1, 0, viewName);

  selectedClipIds.splice(
    (index + 1) * stageNameColumns.value.length,
    0,
    ...stageNameColumns.value.map((stageName) => getDefaultClipForViewAndStage(viewName, stageName))
  );
}

function removeView(index: number): void {
  const selectedClipIds = props.selectedClipIds;

  viewNameRows.value.splice(index, 1);
  selectedClipIds.splice(index * stageNameColumns.value.length, stageNameColumns.value.length);
}

function changeView(index: number, viewName: string): void {
  const selectedClipIds = props.selectedClipIds;

  viewNameRows.value[index] = viewName;

  for (let column = 0; column < stageNameColumns.value.length; column++) {
    selectedClipIds[index * stageNameColumns.value.length + column] = getDefaultClipForViewAndStage(
      viewName,
      stageNameColumns.value[column]
    );
  }
}

function getDefaultClipForViewAndStage(view: string, stage: string): string {
  return stressClips.value.filter((c) => c.viewName === view && c.stageName === stage)[0]?.id ?? "";
}

function onChangeSelectedClip(clipIndex: number, clipId: string): void {
  const selectedClipIds = props.selectedClipIds;
  selectedClipIds[clipIndex] = clipId;
}

// Update layout and clips when the study changes
watch(
  () => props.study,
  async () => {
    await loadStressTableLayout();
    setDefaultSelectedClips();
  },
  { immediate: true }
);

function setDefaultSelectedClips(): void {
  const selectedClipIds = props.selectedClipIds;

  selectedClipIds.splice(0, selectedClipIds.length);

  for (const view of viewNameRows.value) {
    for (const stage of stageNameColumns.value) {
      const matchingClips = stressClips.value.filter(
        (c) => c.viewName === view && c.stageName === stage
      );

      selectedClipIds.push(matchingClips[0]?.id ?? "");
    }
  }
}

function adjustViewColumnsForClipsAreaSize() {
  // It takes about 200px to properly display a single row of clips due to the size for the view
  // name dropdown and the add/remove buttons. 72px is subtracted from the height to account for
  // the table header & stage name columns
  const maxRows = Math.floor((clipsAreaHeight.value - 72) / 200);
  const diff = maxRows - viewNameRows.value.length;

  // Dynamically add or remove views to fill the available space, up to a maximum of 4
  if (diff !== 0) {
    const action = diff > 0 ? addView : removeView;
    for (let i = 0; i < Math.abs(diff); i++) {
      if (viewNameRows.value.length >= 4 && diff > 0) {
        break;
      }

      action(viewNameRows.value.length - (diff > 0 ? 1 : 2), viewNames.value[0]);
    }
  }
}

watch(clipsAreaHeight, adjustViewColumnsForClipsAreaSize);

useEventListener(window, "resize", () => adjustViewColumnsForClipsAreaSize);
</script>
