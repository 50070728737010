/**
 * Defines all of the available study types. When DICOMs are processed an attempt is made to
 * correctly set this study type based on DICOM tags etc. It can also be manually set by users.
 */
export enum StudyType {
  NotSpecified = "notSpecified",

  Angiogram = "angiogram",

  CT = "ct",
  CTCardiac = "ctCardiac",

  MR = "mr",
  MRCardiac = "mrCardiac",

  Nuclear = "nuclear",

  US = "ultrasound",
  USEchocardiogramResting = "ultrasoundEchocardiogramResting",
  USEchocardiogramStress = "ultrasoundEchocardiogramStress",
  USEchocardiogramStressAndResting = "ultrasoundEchocardiogramStressAndResting",
  USEchocardiogramStressExercise = "ultrasoundEchocardiogramStressExercise",
  USEchocardiogramStressExerciseAndResting = "ultrasoundEchocardiogramStressExerciseAndResting",
  USEchocardiogramStressPharmacological = "ultrasoundEchocardiogramStressPharmacological",
  USEchocardiogramStressPharmacologicalAndResting = "ultrasoundEchocardiogramStressPharmacologicalAndResting",
  USEchocardiogramTransesophageal = "ultrasoundEchocardiogramTransesophageal",
  USVascular = "ultrasoundVascular",
  USVascularAbdominal = "ultrasoundVascularAbdominal",
  USVascularAnkleBrachialIndex = "ultrasoundVascularAnkleBrachialIndex",
  USVascularCarotid = "ultrasoundVascularCarotid",
  USVascularArterial = "ultrasoundVascularArterial",
  USVascularRenal = "ultrasoundVascularRenal",
  USVascularVenous = "ultrasoundVascularVenous",
}

export const studyTypeDisplayName: Record<StudyType, string> = {
  [StudyType.NotSpecified]: "",
  [StudyType.Angiogram]: "Angiogram",
  [StudyType.CT]: "CT",
  [StudyType.CTCardiac]: "CT Cardiac",
  [StudyType.MR]: "MR",
  [StudyType.MRCardiac]: "MR Cardiac",
  [StudyType.Nuclear]: "Nuclear",
  [StudyType.US]: "US",
  [StudyType.USEchocardiogramResting]: "Echo",
  [StudyType.USEchocardiogramStress]: "Echo - Stress",
  [StudyType.USEchocardiogramStressAndResting]: "Echo - Stress & Resting",
  [StudyType.USEchocardiogramStressExercise]: "Echo - Exercise Stress",
  [StudyType.USEchocardiogramStressExerciseAndResting]: "Echo - Exercise Stress & Resting",
  [StudyType.USEchocardiogramStressPharmacological]: "Echo - Pharmacological Stress",
  [StudyType.USEchocardiogramStressPharmacologicalAndResting]:
    "Echo - Pharmacological Stress & Resting",
  [StudyType.USEchocardiogramTransesophageal]: "Echo - Transesophageal (TEE/TOE)",
  [StudyType.USVascular]: "Vasc",
  [StudyType.USVascularAbdominal]: "Vasc - Abdomen",
  [StudyType.USVascularAnkleBrachialIndex]: "Vasc - ABI",
  [StudyType.USVascularArterial]: "Vasc - Arterial",
  [StudyType.USVascularCarotid]: "Vasc - Carotid",
  [StudyType.USVascularRenal]: "Vasc - Renal",
  [StudyType.USVascularVenous]: "Vasc - Venous",
};

export const studyTypesForModality: Record<string, StudyType[] | undefined> = {
  CT: [StudyType.CT, StudyType.CTCardiac],
  MR: [StudyType.MR, StudyType.MRCardiac],
  NM: [StudyType.Nuclear],
  US: [
    StudyType.US,
    StudyType.USEchocardiogramResting,
    StudyType.USEchocardiogramStress,
    StudyType.USEchocardiogramStressAndResting,
    StudyType.USEchocardiogramStressExercise,
    StudyType.USEchocardiogramStressExerciseAndResting,
    StudyType.USEchocardiogramStressPharmacological,
    StudyType.USEchocardiogramStressPharmacologicalAndResting,
    StudyType.USEchocardiogramTransesophageal,
    StudyType.USVascular,
    StudyType.USVascularAbdominal,
    StudyType.USVascularAnkleBrachialIndex,
    StudyType.USVascularArterial,
    StudyType.USVascularCarotid,
    StudyType.USVascularRenal,
    StudyType.USVascularVenous,
  ],
  XA: [StudyType.Angiogram],
};

export const ECHOCARDIOGRAM_STUDY_TYPES = [
  StudyType.US,
  StudyType.USEchocardiogramResting,
  StudyType.USEchocardiogramStress,
  StudyType.USEchocardiogramStressAndResting,
  StudyType.USEchocardiogramStressExercise,
  StudyType.USEchocardiogramStressExerciseAndResting,
  StudyType.USEchocardiogramStressPharmacological,
  StudyType.USEchocardiogramStressPharmacologicalAndResting,
  StudyType.USEchocardiogramTransesophageal,
];

export const VASCULAR_STUDY_TYPES = [
  StudyType.USVascular,
  StudyType.USVascularAbdominal,
  StudyType.USVascularAnkleBrachialIndex,
  StudyType.USVascularArterial,
  StudyType.USVascularCarotid,
  StudyType.USVascularRenal,
  StudyType.USVascularVenous,
];
