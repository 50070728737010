import { ClipProcessState } from "./study-clip-processed-files";
import type { StudyClip } from "./study-clip.entity";

/**
 * Extracts all the clips from a study's set of series.
 */
export function getClips<T extends object>(study: {
  series?: ({ clips?: (T | undefined | null)[] } | undefined)[];
}): T[] {
  if (study.series === undefined) {
    return [];
  }

  return study.series
    .flatMap((series) => Object.values(series?.clips ?? []))
    .filter((i): i is T => i !== null && i !== undefined);
}

export function isClipProcessed(clip: StudyClip): boolean {
  return [
    ClipProcessState.Completed,
    ClipProcessState.CompletedWithoutClipFiles,
    ClipProcessState.Error,
  ].includes(clip.processState);
}
