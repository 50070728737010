<template>
  <ContextMenuSubContent
    class="context-menu-sub-content"
    :class="props.class"
    v-bind="forwardedProps"
  >
    <slot />
  </ContextMenuSubContent>
</template>

<script setup lang="ts">
import { type ContextMenuSubContentProps, ContextMenuSubContent } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuSubContentProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = usePropsExcludingClass(props);
</script>

<style scoped lang="scss">
:deep(.context-menu-sub-content) {
  border: solid 1px var(--border-color-2);
  background-color: var(--bg-color-6);
  border-radius: 4px;
  z-index: 1;

  animation: grow-in 100ms ease;
  transform-origin: var(--radix-context-menu-content-transform-origin);
}

@keyframes grow-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
