export enum ClipProcessState {
  // Clip hasn't started processing yet.
  NotStarted = "NotStarted",

  // Clip is queued for processing.
  Queued = "Queued",

  // Clip is currently being processed.
  Processing = "Processing",

  // Clip has been processed but files were not generated (case of migrated studies).
  CompletedWithoutClipFiles = "CompletedWithoutClipFiles",

  // Clip has been fully processed.
  Completed = "Completed",

  // Clip processing has failed after multiple retries.
  Error = "Error",
}

export enum NRRDProcessState {
  NotStarted = "NotStarted",
  Processing = "Processing",
  Completed = "Completed",
}

export enum ProcessedFileExtension {
  Thumbnail = ".thumbnail.webp",
  AnimatedThumbnail = ".thumbnail-animated.webp",
  WebpPacked = ".webp-packed",
  Mp4 = ".mp4",
  Nrrd = ".nrrd",
}

export type ProcessedFileSizes = Partial<Record<ProcessedFileExtension, number>>;
