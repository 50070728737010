<template>
  <div class="add-custom-measurement">
    <b>Create Measurement</b>

    <div class="expanded-content-container" @keydown.enter="saveMeasurement">
      <input
        ref="nameInput"
        v-model="measurementName"
        placeholder="Name"
        style="grid-area: name"
        data-testid="custom-measurement-name-box"
        :disabled="isMeasurementNameLocked"
        @input="autoselectCustomMeasurementUnit"
      />
      <input
        v-model="measurementValue"
        placeholder="Value"
        style="grid-area: value; width: 120px"
        data-testid="custom-measurement-value-box"
      />
      <DropdownWidget
        v-model="measurementUnit"
        :items="units"
        :disabled="!isUnitsDropdownEnabled"
        style="height: 28px"
        placeholder="Unit"
        data-testid="custom-measurement-unit-dropdown"
      />
      <button
        class="accented save-button"
        :disabled="!isMeasurementComplete"
        data-testid="save-custom-measurement-btn"
        @click="saveMeasurement"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import DropdownWidget from "@/components/DropdownWidget.vue";
import { useFocus, useTimeoutFn } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import {
  MeasurementUnit,
  getMeasurementPhysicalQuantity,
  getUnitDisplayText,
  getUnitPhysicalQuantity,
} from "../../../backend/src/measurements/measurement-units";
import { Study, StudyMeasurement } from "../utils/study-data";
import { changeBatchMeasurements } from "./measurement-save-helpers";

interface Props {
  study: Study;
  measurement?: StudyMeasurement;
}

interface Emits {
  (event: "close"): void;
}

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const measurementName = ref(props.measurement?.name ?? "");
const measurementValue = ref("");
const measurementUnit = ref("");

const isUnitsDropdownEnabled = ref(true);

const nameInput = ref<HTMLInputElement>();
useFocus(nameInput, { initialValue: true });

const lockedMeasurementName = computed(() => props.measurement?.name);
const customMeasurementName = computed(() => props.measurement?.customName);
const customMeasurementUnit = computed(() => props.measurement?.customUnit);

const isMeasurementNameLocked = computed(() => lockedMeasurementName.value !== undefined);

const units = computed(() => {
  const allUnits = Object.values(MeasurementUnit);

  let filteredUnits = allUnits;

  if (
    customMeasurementName.value !== undefined &&
    customMeasurementUnit.value !== null &&
    customMeasurementUnit.value !== undefined
  ) {
    filteredUnits = [customMeasurementUnit.value];
  } else if (lockedMeasurementName.value !== undefined) {
    filteredUnits = allUnits.filter((unit) => {
      const physicalQuantity = getMeasurementPhysicalQuantity(lockedMeasurementName.value!); // Should be safe before of the previous check
      return physicalQuantity === getUnitPhysicalQuantity(unit);
    });
  }

  return filteredUnits.map((unit) => ({
    text: unit === MeasurementUnit.Ratio ? "Ratio" : getUnitDisplayText(unit),
    value: unit,
  }));
});

const isMeasurementComplete = computed(() => {
  if (!measurementName.value || measurementName.value.trim() === "") {
    return false;
  }

  // Check value is a valid number
  if (isNaN(Number(measurementValue.value)) || measurementValue.value.trim() === "") {
    return false;
  }

  // Check measurement unit has been chosen
  if (measurementUnit.value === "") {
    return false;
  }

  // Check there's no preexisting custom value with the same name but a different unit - this
  // doesn't seem a situation that would ever be desirable
  const preexistingMeasurement = props.study.measurements.find(
    (m) => m.customName === measurementName.value && String(m.customUnit) !== measurementUnit.value
  );
  if (preexistingMeasurement !== undefined) {
    return false;
  }

  return true;
});

function findCustomValueMeasurement(customName: string): StudyMeasurement | undefined {
  return props.study.measurements.find(
    (m) =>
      m.name === MeasurementName.CustomValue &&
      m.customName.toLowerCase() === customName.toLowerCase()
  );
}

function autoselectCustomMeasurementUnit() {
  if (!measurementName.value || measurementName.value.trim() === "") {
    isUnitsDropdownEnabled.value = true;
    return;
  }

  const preexistingMeasurement = findCustomValueMeasurement(measurementName.value);

  if (preexistingMeasurement !== undefined) {
    measurementUnit.value = String(preexistingMeasurement.customUnit);
  } else if (!isUnitsDropdownEnabled.value) {
    measurementUnit.value = "";
  }

  isUnitsDropdownEnabled.value = preexistingMeasurement === undefined;
}

async function saveMeasurement(): Promise<void> {
  if (!isMeasurementComplete.value) {
    return;
  }

  if (
    (await changeBatchMeasurements(props.study, {
      creates: [
        {
          name: MeasurementName.CustomValue,
          customName:
            findCustomValueMeasurement(measurementName.value)?.customName ?? measurementName.value,
          value: Number(measurementValue.value),
          unit: measurementUnit.value as MeasurementUnit,
        },
      ],
    })) === undefined
  ) {
    return;
  }

  // Clear the values after the popper has closed. Clearing them immediately causes them to change
  // briefly as the popper fades out, which looks odd.
  useTimeoutFn(() => {
    measurementName.value = "";
    measurementValue.value = "";
    measurementUnit.value = "";
  }, 250);

  emits("close");
}

watch(
  () => props.measurement,
  () => {
    measurementName.value =
      props.measurement?.customName !== "" && props.measurement?.customName !== undefined
        ? props.measurement.customName
        : props.measurement?.name !== undefined
          ? getMeasurementDisplayName(props.measurement.name, "unindexed")
          : "";
    autoselectCustomMeasurementUnit();
  }
);
</script>

<style scoped lang="scss">
.add-custom-measurement {
  background-color: var(--bg-color-3);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  transition: background-color 100ms ease;

  &:hover {
    background-color: var(--bg-color-3);
  }
}

.expanded-content-container {
  display: grid;
  grid-template-areas:
    "name name"
    "value input"
    "save save";
  grid-template-columns: auto max-content;
  gap: 4px 4px;
}

input {
  padding: 0 6px;
  line-height: 1em;
  height: 28px;

  &:hover {
    background-color: var(--bg-color-2);
  }
  &:focus {
    background-color: var(--bg-color-4);
  }
}

.save-button {
  grid-area: save;
  margin-top: 4px;
}
</style>
