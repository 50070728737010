<template>
  <BackButton button-text="Back to Tenants" back-destination-route-name="settings-billing-all" />

  <template v-if="billingDetails">
    <div class="settings-title">Tenant: {{ billingDetails.name }}</div>

    <div>
      <DropdownWidget
        v-model="selectedPeriod"
        placeholder="Select Period"
        :items="availableBillingPeriods"
        class="dropdown-widget"
      />
    </div>

    <div class="usage-info">
      <div class="field">
        <strong>Echocardiography Studies</strong>
        <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
          {{ billingDetails.usage.echo }} / {{ billingDetails.quotas.echo }}
        </div>
      </div>

      <div class="field">
        <strong>Vascular Studies</strong>
        <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
          {{ billingDetails.usage.vascular }} / {{ billingDetails.quotas.vascular }}
        </div>
      </div>

      <div class="field">
        <strong>Other Studies (unbilled)</strong>
        <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
          {{ billingDetails.usage.other }}
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="field">
      <strong>Storage Quota</strong>
      <p>The amount of storage this tenant has subscribed for (in terabytes)</p>
      <div>
        <input
          v-model="editableFields.storageQuota"
          type="number"
          step="0.1"
          min="0"
          @update:model-value="updateBillingDetailsDebounced"
        />
      </div>
    </div>

    <div class="field">
      <strong>Echo Quota</strong>
      <p>The minimum number of echocardiograms this tenant will be billed for per quota period</p>
      <input
        v-model="editableFields.echoQuota"
        type="number"
        @update:model-value="updateBillingDetailsDebounced"
      />
    </div>

    <div class="field">
      <strong>Vascular Quota</strong>
      <p>The minimum number of vascular studies this tenant will be billed for per quota period</p>
      <input
        v-model="editableFields.vascularQuota"
        type="number"
        @update:model-value="updateBillingDetailsDebounced"
      />
    </div>

    <div class="field">
      <strong>Billing Start Date</strong>
      <p>The date this tenant will start to be billed from</p>

      <DatePicker
        v-model="editableFields.serviceStartDate"
        mode="date"
        class="dropdown-widget"
        @update:model-value="updateBillingDetailsDebounced"
      />
    </div>

    <div class="field">
      <strong>Billing Period Length</strong>
      <p>The duration of each billing period in months</p>
      <div class="selectable-text">
        <input v-model="billingDetails.servicePeriodMonths" type="number" min="1" disabled />
      </div>
    </div>
  </template>

  <LoadingIndicator v-else size="2x" />
</template>

<script setup lang="ts">
import DatePicker from "@/components/DatePicker.vue";
import DropdownWidget from "@/components/DropdownWidget.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { addNotification } from "@/utils/notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, reactive, ref, watch } from "vue";
import { BillingTenantGetOneResponseDto } from "../../../backend/src/billing/dto/billing-tenant-get.dto";
import { BillingTenantUpdateOneRequestDto } from "../../../backend/src/billing/dto/billing-tenant-update-one.dto";
import { BillingPeriod } from "../../../backend/src/billing/dto/billing-tenant.dto";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import BackButton from "./components/BackButton.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();
const queryClient = useQueryClient();

const selectedPeriod = ref("");
const billingDetails = ref<BillingTenantGetOneResponseDto | null>(null);

const editableFields = reactive({
  serviceStartDate: new Date() as Date | null,
  storageQuota: "",
  echoQuota: 0,
  vascularQuota: 0,
});

const { isError, isFetched } = useQuery({
  queryKey: ["tenant-details", props.id, selectedPeriod],
  queryFn: async ({ queryKey }) => {
    const [, id, period] = queryKey;

    const url = `/api/billings/${id}`;

    if (period === "") {
      const response = await axios.get<BillingTenantGetOneResponseDto>(url);
      return response.data;
    }

    const selectedPeriodData = billingDetails.value?.allBillingPeriods.find(
      (p) => formatPeriodText(p) === period
    );

    if (selectedPeriodData === undefined) {
      throw new Error("Invalid period selected");
    }

    const params = new URLSearchParams({
      start: selectedPeriodData.start.toString(),
      end: selectedPeriodData.end.toString(),
    });

    const response = await axios.get<BillingTenantGetOneResponseDto>(url, {
      params,
    });
    return response.data;
  },
  select: (data) => {
    billingDetails.value = data;

    editableFields.serviceStartDate =
      billingDetails.value.serviceStartDate !== null
        ? new Date(billingDetails.value.serviceStartDate)
        : null;
    editableFields.storageQuota = bytesToTerabytes(billingDetails.value.quotas.storage).toString();
    editableFields.echoQuota = billingDetails.value.quotas.echo;
    editableFields.vascularQuota = billingDetails.value.quotas.vascular;

    if (selectedPeriod.value === "") {
      setActivePeriodToLatest();
    }
  },
});

watch(isError, (newValue) => {
  if (newValue) {
    void addErrorNotification();
  }
});

const updateMutation = useMutation({
  mutationFn: async (updateDto: BillingTenantUpdateOneRequestDto) => {
    await axios.patch(`/api/billings/${props.id}`, updateDto);
  },
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ["tenant-details"] });
    addNotification({ type: "info", message: "Tenant billing details updated" });
  },
  onError: (error) => {
    addNotification({ type: "error", message: "Failed to update tenant billing details" });
    console.error("Error updating quotas:", error);
  },
});

function setActivePeriodToLatest() {
  if (availableBillingPeriods.value.length > 0) {
    selectedPeriod.value = availableBillingPeriods.value[0].value;
  }
}

async function updateBillingDetails(): Promise<void> {
  if (!isFetched.value || billingDetails.value === null) {
    return;
  }

  const billingStartHasChanged =
    editableFields.serviceStartDate !== billingDetails.value.serviceStartDate;

  await updateMutation.mutateAsync({
    serviceStartDate: editableFields.serviceStartDate?.toISOString() ?? null,
    servicePeriodMonths: 12,
    quotas: {
      storage: terabytesToBytes(parseInt(editableFields.storageQuota)).toString(),
      echo: editableFields.echoQuota,
      vascular: editableFields.vascularQuota,
    },
  });

  if (billingStartHasChanged) {
    setActivePeriodToLatest();
  }
}

const updateBillingDetailsDebounced = useDebounceFn(() => {
  void updateBillingDetails();
}, 1000);

const availableBillingPeriods = computed(() => {
  if (!billingDetails.value) return [];

  const periods = billingDetails.value.allBillingPeriods
    .toSorted((a, b) => {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    })
    .map((period) => ({
      text: formatPeriodText(period),
      value: formatPeriodText(period),
    }));

  if (periods.length > 0) {
    periods[0].text += " (Current)";
  }

  return periods;
});

function addErrorNotification() {
  return addNotification({
    type: "error",
    message: "Failed loading tenant data",
  });
}

function formatPeriodText(period: BillingPeriod) {
  return `${formatDateTime(period.start)} - ${formatDateTime(period.end)}`;
}

function bytesToTerabytes(bytesString: string) {
  const bytes = parseFloat(bytesString);
  return isNaN(bytes) ? 0 : bytes / 1024 / 1024 / 1024 / 1024;
}

function terabytesToBytes(terabytes: number): string {
  return (terabytes * 1024 * 1024 * 1024 * 1024).toString();
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usage-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dropdown-widget {
  width: 15rem;
}

.divider {
  align-self: stretch;
  height: 0;
  border-top: 1px solid var(--border-color-1);
  margin-top: 6px;
  margin-bottom: 6px;
}

input {
  width: 14rem;
}
</style>
