/** Describes the different properties that make up a theme. */
export enum ThemeProperty {
  BorderRadius = "border-radius",

  BackgroundColor1 = "bg-color-1",
  BackgroundColor2 = "bg-color-2",
  BackgroundColor3 = "bg-color-3",
  BackgroundColor4 = "bg-color-4",
  BackgroundColor5 = "bg-color-5",
  BackgroundColor6 = "bg-color-6",
  BackgroundColor7 = "bg-color-7",

  BorderColor1 = "border-color-1",
  BorderColor2 = "border-color-2",

  ConfirmColor1 = "confirm-color-1",
  ConfirmColor2 = "confirm-color-2",

  TextColor1 = "text-color-1",
  TextColor2 = "text-color-2",
  TextColor3 = "text-color-3",

  AccentColor1 = "accent-color-1",
  AccentColor2 = "accent-color-2",
  AccentColorHot = "accent-color-hot",

  AccentColorBlue = "accent-color-blue",
  AccentBackgroundColorBlue = "accent-bg-color-blue",
  AccentBackgroundColorBlueHover = "accent-bg-color-blue-hover",

  AccentColorGreen = "accent-color-green",
  AccentBackgroundColorGreen = "accent-bg-color-green",
  AccentBackgroundColorGreenHover = "accent-bg-color-green-hover",

  AccentColorPurple = "accent-color-purple",
  AccentBackgroundColorPurple = "accent-bg-color-purple",
  AccentBackgroundColorPurple2 = "accent-bg-color-purple-2",
  AccentBackgroundColorPurpleHover = "accent-bg-color-purple-hover",

  ButtonAccentedBackgroundColor = "button-accented-bg-color",
  ButtonAccentedActiveColor = "button-accented-active-color",
  ButtonAccentedDisabledColor = "button-accented-disabled-color",
  ButtonSuccessBackgroundColor = "button-success-bg-color",
  ButtonSuccessActiveColor = "button-success-active-color",
  ButtonSuccessDisabledColor = "button-success-disabled-color",

  InputFocusBorderColor = "input-focus-border-color",

  ClipListSelectedClipBorderColor = "clip-list-selected-clip-border-color",

  NotificationBorderRadius = "notification-border-radius",
  NotificationTextColor = "notification-text-color",
  NotificationIconColor = "notification-icon-color",
  NotificationInfoBackgroundColor = "notification-info-bg-color",
  NotificationInfoIconBackgroundColor = "notification-info-icon-bg-color",
  NotificationInfoCloseIconColor = "notification-info-close-icon-color",
  NotificationErrorBackgroundColor = "notification-error-bg-color",
  NotificationErrorIconBackgroundColor = "notification-error-icon-bg-color",
  NotificationErrorCloseIconColor = "notification-error-close-icon-color",

  ReportContainerBackgroundColor = "report-container-bg-color",
  ReportBannerFinalizedBackgroundColor = "report-banner-finalized-bg-color",
  ReportBannerEditingBackgroundColor = "report-banner-editing-bg-color",
  ReportBoxBackgroundColor = "report-box-bg-color",
  ReportBoxBorderColor = "report-box-border-color",
  ReportTableGridlineColor = "report-table-gridline-color",
  ReportTableHeaderCellBackgroundColor = "report-table-header-cell-bg-color",
  ReportSectionBackgroundColor = "report-section-bg-color",
  ReportWidgetBackgroundColor = "report-widget-bg-color",
  ReportWidgetBackgroundColorHover = "report-widget-bg-color-hover",
  ReportWidgetBorderColor = "report-widget-border-color",
  ReportPlaceholderTextColor = "report-placeholder-text-color",
  ReportPlaceholderTextColorFocused = "report-placeholder-text-color-focused",
  ReportAccentColor1 = "report-accent-color-1",
  ReportAccentColor2 = "report-accent-color-2",
  ReportTextColor1 = "report-text-color-1",
  ReportTextColor2 = "report-text-color-2",
  ReportPopperBackgroundColor = "report-popper-bg-color",
  ReportCheckboxCheckedColor = "report-checkbox-checked-color",
  ReportCheckboxUncheckedColor = "report-checkbox-unchecked-color",
  ReportCheckboxCheckedBackgroundColor = "report-checkbox-checked-background-color",
  ReportWidgetOutlineColorFocused = "report-widget-outline-color-focused",

  ScrollbarTrackColor = "scrollbar-track-color",
  ScrollbarThumbColor = "scrollbar-thumb-color",
  ScrollbarHoverColor = "scrollbar-hover-color",

  RwmaAkineticColor = "rwma-akinetic-color",
  RwmaAneurysmalColor = "rwma-aneurysmal-color",
  RwmaDyskineticColor = "rwma-dyskinetic-color",
  RwmaHypokineticColor = "rwma-hypokinetic-color",
  RwmaNormalColor = "rwma-normal-color",
  RwmaNotWellSeenColor = "rwma-notWellSeen-color",
  RwmaBloodPoolColor = "rwma-blood-pool-color",
  RadiusFull = "radius-full",
}

/** Describes a theme with a name and a set of CSS property values. */
export interface Theme {
  name: string;
  values: Record<ThemeProperty, string>;
}
