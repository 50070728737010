<template>
  <MeasurementCardContextMenu
    :is-indexable="isIndexable"
    :display-option="displayOption"
    :some-values-on="someValuesOn"
    :can-take-measurement="canTakeMeasurement"
    :custom-measurements-enabled="false"
    :study-update-permitted="studyUpdatePermitted"
    @take-measurement="takeMeasurement"
    @toggle-all-selected="toggleAllSelected"
    @update:custom-typed-measurement-name="
      emits('update:custom-typed-measurement-name', measurement)
    "
    @update:display-option="updateDisplayOption"
  >
    <div
      class="measurement-card"
      :class="{ expanded: isExpanded }"
      :data-testid="`measurement-card-${measurement.name}${
        isCustomMeasurement(measurement.name) ? `-${measurement.customName}` : ''
      }`"
      :data-test-expanded="isExpanded"
    >
      <div
        class="measurement-content"
        data-testid="measurement-content"
        :class="{ highlight: props.highlightedMeasurementId === props.measurement.id }"
        @click="onClickCard"
      >
        <div class="top-line">
          <div class="measurement-stack selectable-text">
            <b v-if="isUnindexedValueVisible">
              {{ getMeasurementDisplayName(props.measurement, "unindexed") }}
            </b>

            <b v-if="isIndexedValueVisible">
              {{ getMeasurementDisplayName(props.measurement, "indexed") }}
            </b>
          </div>

          <Tooltip
            v-if="measurement.values.some((m) => m.source === StudyMeasurementValueSource.External)"
            :content="`Measurement contains values from ${[
              ...new Set(
                measurement.values
                  .filter((m) => m.source === StudyMeasurementValueSource.External)
                  .map((m) => m.apiKeyName)
              ),
            ].join(', ')}`"
          >
            <FontAwesomeIcon class="external-mmt-icon" icon="bolt" />
          </Tooltip>

          <MeasurementBadge
            source="mean"
            :style="{ opacity: isMeanBadgeVisible ? 1 : 0, transition: 'opacity 100ms ease' }"
          />

          <LoadingIndicator v-if="isWorking" />

          <div class="measurement-stack" style="align-items: flex-end; margin-left: auto">
            <MeasurementCardValue
              v-if="isUnindexedValueVisible"
              :measurement="measurement"
              :reference-range-set-name="referenceRangeSetName"
              :indexed="false"
              :patient-metrics="undefined"
              :show-mean-badge="isMeanBadgeVisible"
            />

            <MeasurementCardValue
              v-if="isIndexedValueVisible"
              :measurement="measurement"
              :reference-range-set-name="referenceRangeSetName"
              :indexed="true"
              :patient-metrics="patientMetrics"
              :show-mean-badge="isMeanBadgeVisible"
            />
          </div>
        </div>

        <div v-if="isExpanded" class="expanded-content-container" @click.stop>
          <template
            v-for="(measurementValue, index) in sortedMeasurementValues"
            :key="measurementValue.id"
          >
            <div
              class="values-list-item"
              :data-testid="`value-list-item-${index}`"
              :data-test-mmt-value-id="`${measurementValue.id}`"
              :data-test-mmt-creation-batch-id="`${measurementValue.measurementCreationBatchId}`"
              :class="{
                'visible-on-selected-clip': visibleFrames.some(
                  (visibleFrame) =>
                    visibleFrame.studyClipId === measurementValue.studyClipId &&
                    visibleFrame.frame === measurementValue.frame &&
                    (visibleFrame.soloMeasurementValueId === undefined ||
                      visibleFrame.soloMeasurementValueId === measurementValue.id)
                ),
                'has-contour':
                  measurementValue.studyClipId !== null &&
                  measurementValue.frame !== null &&
                  measurementValue.contour !== null,
                'hovered-in-clip-viewer': props.hoveredMeasurementValueId === measurementValue.id,
                'editing-custom-value': measurementValue.id === customValueBeingEditedId,
              }"
              @click="emits('jump-to-value', measurementValue)"
            >
              <ToggleSwitch
                data-testid="toggle-value-selected"
                :enabled="isStudyUpdatePermitted(study)"
                :model-value="measurementValue.selected"
                @update:model-value="
                  (newValue) =>
                    measurementValue.id !== customValueBeingEditedId &&
                    onChangeMeasurementValueSelected(measurementValue, newValue)
                "
              >
                <Tooltip
                  v-if="measurementValue.id !== customValueBeingEditedId"
                  :visible="(measurementValue.calculationInputs ?? []).length > 0"
                >
                  {{
                    getStudyMeasurementDisplayValue(
                      {
                        name: measurement.name,
                        values: [{ ...measurementValue, selected: true }],
                        customUnit: measurement.customUnit,
                      },
                      measurement.displayOption === StudyMeasurementDisplayOption.PreferIndexed &&
                        isIndexable
                        ? "indexed"
                        : "unindexed",
                      patientMetrics
                    )?.fullText ?? getFallbackDisplayValue(measurement)
                  }}

                  <template #content>
                    <MeasurementCardCalculationInputTooltipContent
                      :study="study"
                      :measurement-value="measurementValue"
                    />
                  </template>
                </Tooltip>
              </ToggleSwitch>

              <template v-if="measurementValue.id !== customValueBeingEditedId">
                <Tooltip
                  :visible="
                    measurementValue.createdAt !== null &&
                    measurementValue.createdById !== null &&
                    (measurementValue.source === StudyMeasurementValueSource.Manual ||
                      measurementValue.source === StudyMeasurementValueSource.Calculated)
                  "
                >
                  <MeasurementBadge
                    :source="measurementValue.source"
                    :value="measurementValue"
                    class="jump-to-value"
                    data-testid="source-badge"
                  />
                  <template #content>
                    <div :data-testid="`mmt-value-tooltip-${measurement.name}-${index}`">
                      {{ getLastUpdateTooltipTextForMeasurementValue(userList, measurementValue) }}
                    </div>
                  </template>
                </Tooltip>

                <div class="buttons">
                  <Tooltip
                    v-if="
                      measurementValue.source === StudyMeasurementValueSource.Manual ||
                      measurementValue.source === StudyMeasurementValueSource.Calculated ||
                      measurementValue.source === StudyMeasurementValueSource.External
                    "
                    :content="
                      studyUpdatePermitted
                        ? 'Delete this measurement value'
                        : 'Measurements can\'t be saved because the report is signed. Start an amendment to delete measurements.'
                    "
                    max-width="320px"
                    style="margin: 0 4px 0 auto"
                  >
                    <FontAwesomeIcon
                      data-testid="delete-mmt-value"
                      class="mmt-value-icon"
                      :class="{ disabled: !studyUpdatePermitted }"
                      icon="trash"
                      @click.stop="measurementValueToDelete = measurementValue"
                    />
                  </Tooltip>

                  <Tooltip
                    v-if="isMeasurementEditingSupported(measurementValue) && studyUpdatePermitted"
                    :content="
                      !isCurrentlyEditingValue(measurementValue)
                        ? `Edit this measurement value`
                        : `Cancel editing`
                    "
                    style="margin: 0 4px 0 auto"
                  >
                    <FontAwesomeIcon
                      icon="pen"
                      class="mmt-value-icon"
                      :class="{
                        'currently-editing': isCurrentlyEditingValue(measurementValue),
                      }"
                      :data-testid="`edit-mmt-value-${index}`"
                      @click.stop="onEditManualMeasurement(measurementValue)"
                    />
                  </Tooltip>
                </div>
              </template>

              <MeasurementCardEditCustomValue
                v-else
                :study="study"
                :measurement="measurement"
                :measurement-value="measurementValue"
                @close="customValueBeingEditedId = null"
              />
            </div>
          </template>

          <div class="measurement-card-footer">
            <DropdownWidget
              v-model="displayOption"
              :style="{ visibility: isIndexable ? 'visible' : 'hidden' }"
              :disabled="!studyUpdatePermitted"
              :items="[
                { value: StudyMeasurementDisplayOption.Unindexed, text: 'Display unindexed' },
                { value: StudyMeasurementDisplayOption.PreferIndexed, text: 'Display indexed' },
                {
                  value: StudyMeasurementDisplayOption.UnindexedAndIndexed,
                  text: 'Display unindexed and indexed',
                },
              ]"
              @update:model-value="onSaveDisplayOption($event as StudyMeasurementDisplayOption)"
            />

            <IconButton
              icon="ellipsis"
              type="more-icon"
              :disabled="!studyUpdatePermitted && !canTakeMeasurement"
              @click="(event: MouseEvent) => openContextMenu(event)"
            />
          </div>
        </div>
      </div>
      <DeleteMeasurementValueModal
        v-if="measurementValueToDelete !== null"
        :study="study"
        :measurement="measurement"
        :value-to-delete="measurementValueToDelete"
        @close="measurementValueToDelete = null"
      />
    </div>
  </MeasurementCardContextMenu>
</template>

<script setup lang="ts">
import {
  MeasurementName,
  isCustomMeasurement,
} from "@/../../backend/src/measurements/measurement-names";
import {
  StudyMeasurementDisplayOption,
  StudyMeasurementValueSource,
} from "@/../../backend/src/studies/study-measurement-enums";
import IconButton from "@/components/IconButton.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import MeasurementBadge from "@/measurements/MeasurementBadge.vue";
import {
  findMainMeasurementAndValueForBatch,
  getLastUpdateTooltipTextForMeasurementValue,
  getValuesInBatch,
  isEjectionFractionMeasurementWithoutAssociatedVolumes,
  isMeasurementIndexable,
} from "@/measurements/measurement-helpers";
import { ReferenceRangeSetName } from "@/reference-ranges/reference-range-sets";
import { addNotification } from "@/utils/notifications";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { computed, ref, watch } from "vue";
import { getDrawableUniplaneEjectionFractionMeasurements } from "../../../backend/src/measurements/drawable-measurements";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
  getUnitDisplayText,
  type PatientMetrics,
} from "../../../backend/src/measurements/measurement-display";
import { MeasurementToolName } from "../../../backend/src/measurements/measurement-tool-names";
import { getMeasurementDisplayUnit } from "../../../backend/src/measurements/measurement-units";
import { findClipRegionOrCreateMockRegion } from "../../../backend/src/studies/study-clip-region";
import { getClips } from "../../../backend/src/studies/study-helpers";
import { isStudyUpdatePermitted } from "../auth/authorization";
import DropdownWidget from "../components/DropdownWidget.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Tooltip from "../components/Tooltip.vue";
import { useUserList } from "../utils/users-list";
import DeleteMeasurementValueModal from "./DeleteMeasurementValueModal.vue";
import MeasurementCardCalculationInputTooltipContent from "./MeasurementCardCalculationInputTooltipContent.vue";
import MeasurementCardContextMenu from "./MeasurementCardContextMenu.vue";
import MeasurementCardEditCustomValue from "./MeasurementCardEditCustomValue.vue";
import MeasurementCardValue from "./MeasurementCardValue.vue";
import {
  updateMeasurementValueSelected,
  updateMeasurementValuesSelected,
} from "./measurement-save-helpers";
import { getToolNameForMeasurement } from "./measurement-tool-helpers";
import { activeMeasurement, startMeasuring, stopMeasuring } from "./measurement-tool-state";

interface Props {
  study: Study;
  measurement: StudyMeasurement;
  referenceRangeSetName: ReferenceRangeSetName;
  isExpanded: boolean;
  showExpansionArrow?: boolean;
  patientMetrics: PatientMetrics | undefined;
  visibleFrames: {
    studyClipId: string;
    frame: number;
    soloMeasurementValueId: string | undefined;
  }[];
  highlightedMeasurementId: string | null;
  hoveredMeasurementValueId: string | null;
  canTakeMeasurement: boolean;
  studyUpdatePermitted: boolean;
}

interface Emits {
  (event: "update:isExpanded", newValue: boolean): void;
  (event: "jump-to-value", value: StudyMeasurementValue): void;
  (event: "take-measurement", value: { tool: MeasurementToolName; name: MeasurementName }): void;
  (event: "update:measurement-display-option", value: StudyMeasurementDisplayOption): void;
  (event: "update:custom-typed-measurement-name", value: StudyMeasurement): void;
}

const props = withDefaults(defineProps<Props>(), {
  showExpansionArrow: false,
});

const emits = defineEmits<Emits>();

const customValueBeingEditedId = ref<string | null>(null);

const measurementValueToDelete = ref<StudyMeasurementValue | null>(null);

const someValuesOn = computed(() => props.measurement.values.some((v) => v.selected));

// Clear the measurement value to delete when it no longer exists
watch(
  () => props.measurement.values,
  () => {
    if (
      measurementValueToDelete.value !== null &&
      !props.measurement.values.includes(measurementValueToDelete.value)
    ) {
      measurementValueToDelete.value = null;
    }
  },
  { deep: true }
);

const sortedMeasurementValues = computed(() =>
  [...props.measurement.values].sort(
    (a, b) => (a.value ?? Number.MAX_SAFE_INTEGER) - (b.value ?? Number.MAX_SAFE_INTEGER)
  )
);

const isIndexable = computed(
  () => isMeasurementIndexable(props.measurement.name) && !!props.patientMetrics
);

const isUnindexedValueVisible = computed(
  () =>
    props.measurement.displayOption !== StudyMeasurementDisplayOption.PreferIndexed ||
    !isIndexable.value
);

const isIndexedValueVisible = computed(
  () =>
    props.measurement.displayOption !== StudyMeasurementDisplayOption.Unindexed && isIndexable.value
);

const isMeanBadgeVisible = computed(
  () => props.measurement.values.filter((m) => m.selected).length > 1
);

const displayOption = computed({
  get() {
    return props.measurement.displayOption ?? StudyMeasurementDisplayOption.PreferIndexed;
  },
  set(newValue: StudyMeasurementDisplayOption) {
    emits("update:measurement-display-option", newValue);
  },
});

const isWorking = ref(false);

const { userList } = useUserList();

function onClickCard(): void {
  emits("update:isExpanded", !props.isExpanded);
}

async function updateDisplayOption(newValue: StudyMeasurementDisplayOption): Promise<void> {
  displayOption.value = newValue;
  await onSaveDisplayOption(newValue);
}

function isCurrentlyEditingValue(value: StudyMeasurementValue): boolean {
  return (
    customValueBeingEditedId.value === value.id ||
    (activeMeasurement.value.editingMeasurementBatchId.value !== null &&
      activeMeasurement.value.editingMeasurementBatchId.value === value.measurementCreationBatchId)
  );
}

async function onChangeMeasurementValueSelected(
  measurementValue: StudyMeasurementValue,
  newValue: boolean
): Promise<void> {
  isWorking.value = true;
  await updateMeasurementValueSelected(props.study, measurementValue, newValue);
  isWorking.value = false;
}

async function onSaveDisplayOption(displayOption: StudyMeasurementDisplayOption): Promise<void> {
  isWorking.value = true;

  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await axios.patch(`/api/studies/${props.study.id}/measurements/${props.measurement.id}`, {
      displayOption,
    });
  } catch {
    addNotification({ type: "error", message: "Failed saving measurement" });
    return;
  } finally {
    isWorking.value = false;
  }
}

/**
 * Returns the value to use when the measurement value can't be computed, which occurs when a
 * calculation input is an average without any selected values.
 */
function getFallbackDisplayValue(measurement: StudyMeasurement): string {
  return `— ${getUnitDisplayText(
    measurement.name === MeasurementName.CustomValue && measurement.customUnit !== null
      ? measurement.customUnit
      : getMeasurementDisplayUnit(measurement.name)
  )}`;
}

function isMeasurementEditingSupported(value: StudyMeasurementValue): boolean {
  // Custom value measurements are always editable
  if (props.measurement.name === MeasurementName.CustomValue) {
    return true;
  }

  // To be editable, a measurement value must have a valid tool, creation batch ID, and contour
  if (value.measurementTool === null || value.contour === null) {
    return false;
  }

  // If this is a detached associated measurement then it can't be edited. This shouldn't be
  // possible moving forward, but may be present on historical data.
  const mainMeasurement = findMainMeasurementAndValueForBatch(
    props.study,
    value.measurementCreationBatchId
  );
  if (mainMeasurement === undefined) {
    return false;
  }

  // If this is a volume or EF measurement that's part of an EF measurement in which one or more of
  // the volumes has been deleted, then the EF tool can't be re-activated to edit the measurement.
  if (
    isEjectionFractionMeasurementWithoutAssociatedVolumes(
      props.study,
      mainMeasurement.measurement,
      mainMeasurement.value
    )
  ) {
    return false;
  }

  return true;
}

function takeMeasurement(): void {
  const measurementToolName = getToolNameForMeasurement(props.measurement);
  emits("take-measurement", { tool: measurementToolName, name: props.measurement.name });
}

function openContextMenu(event: MouseEvent): void {
  event.preventDefault();
  const moreIcon = event.target as HTMLElement;
  const rect = moreIcon.getBoundingClientRect();
  const contextMenuEvent = new MouseEvent("contextmenu", {
    bubbles: true,
    cancelable: true,
    view: window,
    clientX: rect.left + rect.width,
    clientY: rect.top,
  });
  moreIcon.dispatchEvent(contextMenuEvent);
}

function onEditManualMeasurement(measurementValue: StudyMeasurementValue): void {
  if (
    props.measurement.name === MeasurementName.CustomValue &&
    props.measurement.customUnit !== null
  ) {
    customValueBeingEditedId.value =
      customValueBeingEditedId.value === measurementValue.id ? null : measurementValue.id;
    return;
  }

  if (isCurrentlyEditingValue(measurementValue)) {
    stopMeasuring();
    return;
  }

  // Find the main measurement to start the editing on
  const mainMeasurement = findMainMeasurementAndValueForBatch(
    props.study,
    measurementValue.measurementCreationBatchId
  );
  if (mainMeasurement === undefined) {
    return;
  }

  const { measurement: measurementToEdit, value: measurementValueToEdit } = mainMeasurement;

  // In order to start editing the measurement we need to determine which region of the clip it was
  // taken in. This can be done by looking at its contour.
  let clip = getClips(props.study).find((c) => c.id === measurementValue.studyClipId);
  let contourToFindRegionWith = measurementValueToEdit.contour;

  const series = props.study.series.find((s) => s.id === clip?.seriesId);

  // However, EF measurements don't have a contour, but their associated volume measurements do. The
  // In this case, the region for the EF measurement is determined by finding the region for one of
  // the associated volume measurements.
  if (getDrawableUniplaneEjectionFractionMeasurements().includes(measurementToEdit.name)) {
    const valuesInBatch = getValuesInBatch(
      props.study,
      measurementValue.measurementCreationBatchId
    );

    const efVolumeMeasurement = valuesInBatch.find(
      (m) => m.studyClipId !== null && m.contour !== null
    );
    if (efVolumeMeasurement === undefined) {
      return;
    }

    clip = getClips(props.study).find((c) => c.id === efVolumeMeasurement.studyClipId);
    contourToFindRegionWith = efVolumeMeasurement.contour;
  }

  if (
    clip === undefined ||
    series === undefined ||
    contourToFindRegionWith === null ||
    measurementValueToEdit.measurementTool === null
  ) {
    return;
  }

  const region = findClipRegionOrCreateMockRegion(
    clip,
    series,
    contourToFindRegionWith,
    measurementValue.plane ?? undefined
  );
  if (region === undefined) {
    return;
  }

  emits("jump-to-value", measurementValue);

  startMeasuring({
    tool: measurementValueToEdit.measurementTool,
    study: props.study,
    clipId: clip.id,
    region,
  });

  activeMeasurement.value.loadSavedMeasurement({
    measurementName: measurementToEdit.name,
    customName: measurementToEdit.customName,
    ...measurementValueToEdit,
  });
}

/**
 * If some values are selected, toggle all values off.
 * If no values are selected, toggle all values on.
 */
async function toggleAllSelected(): Promise<void> {
  const selected = !someValuesOn.value;
  const measurementValues = props.measurement.values.map((value) => ({
    measurementValue: value,
    selected,
  }));

  await updateMeasurementValuesSelected(props.study, props.measurement.id, measurementValues);
}
</script>

<style scoped lang="scss">
.measurement-card {
  background-color: var(--bg-color-2);
  display: flex;
  position: relative;
  transition:
    background-color 100ms ease,
    filter 100ms ease;
  border-bottom: 1px solid var(--border-color-1);

  &.expanded,
  &:hover {
    background-color: var(--bg-color-3);
  }
}

.measurement-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    filter: brightness(1.15);
  }

  // Animation that highlights the measurement card with an animated outline and background color
  &.highlight {
    outline: 1px solid var(--accent-color-1);
    outline-offset: -1px;
    outline-color: rgba(0, 0, 0, 0);

    animation: highlight-item 2500ms ease 0s 1;

    @keyframes highlight-item {
      0% {
        background-color: var(--bg-color-4);
        outline-color: var(--clip-list-selected-clip-border-color);
      }
    }
  }
}

.top-line {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color-1);
  padding: 6px 8px;
}

.measurement-stack {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mmt-value-icon {
  transition:
    color 100ms ease,
    opacity 100ms ease;
  opacity: 0;
  font-size: 0.9em;
  cursor: pointer;

  &:hover,
  &.currently-editing {
    opacity: 1 !important;
  }

  &.currently-editing {
    color: var(--confirm-color-2);
  }

  &.disabled {
    pointer-events: none;
  }
}

.values-list-item {
  display: flex;
  align-items: center;
  padding: 2px;
  gap: 10px;
  border-radius: var(--border-radius);
  transition:
    filter 100ms ease,
    background-color 100ms ease;

  &.visible-on-selected-clip {
    background-color: var(--bg-color-4);
  }

  &.hovered-in-clip-viewer {
    filter: brightness(150%);
    background-color: var(--bg-color-4);
  }

  &.editing-custom-value {
    gap: 0;
  }

  &.has-contour {
    cursor: pointer;

    &:hover {
      background-color: var(--bg-color-4);
      filter: brightness(110%);
    }
  }

  &:hover {
    .mmt-value-icon {
      opacity: 0.7;

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }
}

.jump-to-value {
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.expanded-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--bg-color-7);
  padding: 6px 8px;
  cursor: default;
}

.external-mmt-icon {
  color: var(--button-accented-bg-color);
}

.measurement-card-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
</style>
