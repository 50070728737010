<template>
  <AuthPage
    auth-button-visible
    :auth-button-text="authButtonText"
    :auth-button-enabled="isAuthButtonEnabled"
    :auth-button-spinner="isWorking"
    :back-icon-visible="stage === Stage.PasswordReset"
    @submit="onSubmit"
    @back="stage = Stage.PasswordInput"
  >
    <template v-if="isReady">
      <template v-if="stage === Stage.EmailInput">
        <div class="auth-text">
          <b style="font-size: 16px">Login to continue</b>
        </div>
        <div class="field">
          <input
            ref="emailInputElement"
            v-model="email"
            data-testid="email"
            autocomplete="username"
            placeholder="Email"
            required
          />
        </div>
      </template>

      <template v-else-if="stage === Stage.PasswordInput">
        <div class="auth-text">
          <div>{{ email }}</div>
        </div>

        <div class="field">
          <PasswordInput v-model="password" data-testid="password" placeholder="Password" />
          <div style="display: flex; justify-content: space-between; align-items: flex-end">
            <a
              style="font-size: 0.9em; margin-left: auto"
              data-testid="forgot-password"
              @click="stage = Stage.PasswordReset"
            >
              Forgot password?
            </a>
          </div>
        </div>
      </template>

      <template v-else-if="stage === Stage.TenantSelection">
        <div class="auth-text">
          <div>{{ email }}</div>
          <b style="font-size: 16px">Which tenant are you using?</b>
        </div>
        <DropdownWidget
          v-model="selectedTenantId"
          style="width: 100%"
          :items="
            tenants.map((t) => {
              return { value: t.id, text: t.name };
            })
          "
        />
      </template>

      <template v-else-if="stage === Stage.TOTP">
        <div class="auth-text">
          <b>Enter MFA code</b>
        </div>
        <div class="field">
          <input
            ref="totpInputElement"
            v-model="totpToken"
            data-testid="totp-input"
            @keydown.enter="onSubmit"
          />
        </div>

        <Checkbox v-model="rememberDevice" data-testid="remember-device" style="align-self: center">
          Remember this device for 30 days
        </Checkbox>
      </template>

      <template v-else-if="stage === Stage.PasswordReset">
        <span style="margin-bottom: 8px">
          Please enter your email, and a link to reset your password will be sent to your email
        </span>
        <input
          ref="emailInputElement"
          v-model="email"
          data-testid="email"
          placeholder="Email"
          required
        />
      </template>

      <template v-else-if="stage === Stage.PasswordResetEmailSent">
        <div class="instructions" data-testid="password-reset-instructions">
          <FontAwesomeIcon icon="check" size="lg" />
          Check your inbox for a password reset email
        </div>
      </template>
    </template>
    <template v-if="stage === Stage.EmailInput" #footer>
      <div class="sso-buttons">
        <div class="sso-divider">
          <b>Or</b>
        </div>
        <button class="microsoft-button" @click.prevent="onMicrosoftLogin">
          <FontAwesomeIcon icon="fa-brands fa-microsoft" />
          Continue with Microsoft
        </button>
      </div>
    </template>
  </AuthPage>
</template>

<script setup lang="ts">
import { IS_DEV_MODE } from "@/environment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import {
  SSO_NOT_ENABLED,
  TOO_MANY_USERS,
  USER_NOT_FOUND,
} from "../../../backend/src/auth/auth.constants";
import { PASSWORD_MIN_LENGTH } from "../../../backend/src/auth/password-strength-check";
import { SessionCreateResult } from "../../../backend/src/auth/session-create-result";
import Checkbox from "../components/Checkbox.vue";
import DropdownWidget from "../components/DropdownWidget.vue";
import PasswordInput from "../components/PasswordInput.vue";
import router from "../router";
import { addNotification, clearNotifications } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { SelectableTenants, signInWithEmail } from "./authentication";
import AuthPage from "./AuthPage.vue";
import { fetchCurrentTenantAndUser } from "./current-session";

const error = useRouteQuery("error");
const redirectTo = useRouteQuery("redirectTo");

clearNotifications();

function onMicrosoftLogin() {
  window.location.href = "/api/auth/microsoft";
}

async function followRedirect(): Promise<void> {
  await router.push({ path: [redirectTo.value].flat()[0] ?? "/" });
}

const isReady = ref(false);

onMounted(async () => {
  if ((await fetchCurrentTenantAndUser()) === 200) {
    await followRedirect();
  }

  if (error.value !== null) {
    if (error.value === TOO_MANY_USERS) {
      addNotification({
        type: "error",
        message: "Microsoft login is not available for users with multiple accounts",
      });
    } else if (error.value === USER_NOT_FOUND) {
      addNotification({
        type: "error",
        message: "User not found",
      });
    } else if (error.value === SSO_NOT_ENABLED) {
      addNotification({
        type: "error",
        message: "Microsoft SSO is not enabled for your tenant",
      });
    }
  }

  isReady.value = true;
});

enum Stage {
  EmailInput,
  PasswordInput,
  TenantSelection,
  TOTP,
  PasswordReset,
  PasswordResetEmailSent,
}

const stage = ref<Stage>(Stage.EmailInput);

const email = ref("");
const password = ref("");
const totpToken = ref("");
const rememberDevice = ref(false);

const tenants = ref<SelectableTenants[]>([]);
const selectedTenantId = ref("");
const isEmailValid = computed(
  () => email.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) !== null
);
const isPasswordValid = computed(() => password.value.length >= PASSWORD_MIN_LENGTH);

if (IS_DEV_MODE) {
  email.value = "dev-administrator@heartlab.com";
  password.value = "heartlab-12";
}

const authButtonText = computed(() => {
  switch (stage.value) {
    case Stage.EmailInput:
      return "Continue";
    case Stage.PasswordInput:
      return "Continue";
    case Stage.TenantSelection:
      return "Login";
    case Stage.TOTP:
      return "Verify";
    case Stage.PasswordReset:
      return "Reset Password";
    case Stage.PasswordResetEmailSent:
      return "Back to Login";
    default:
      return "Login";
  }
});

const emailInputElement = ref<HTMLInputElement | null>();
const { focused: emailInputFocused } = useFocus(emailInputElement, { initialValue: true });

const totpInputElement = ref<HTMLInputElement | null>();
const { focused: totpInputFocused } = useFocus(totpInputElement);

const isAuthButtonEnabled = computed(() => {
  switch (stage.value) {
    case Stage.EmailInput:
      return isEmailValid.value;
    case Stage.PasswordInput:
      return isPasswordValid.value;
    case Stage.TenantSelection:
      return selectedTenantId.value !== "";
    case Stage.TOTP:
      return totpToken.value.length === 6;
    default:
      return stage.value === Stage.PasswordReset || isPasswordValid.value;
  }
});

const isWorking = ref(false);

async function onSubmit(): Promise<void> {
  isWorking.value = true;

  switch (stage.value) {
    case Stage.EmailInput:
      onEmailInput();
      break;
    case Stage.PasswordInput:
    case Stage.TOTP:
    case Stage.TenantSelection:
      await onLoginAttempt();
      break;
    case Stage.PasswordReset:
      await onPasswordReset();
      break;
    case Stage.PasswordResetEmailSent:
      onEmailSent();
      break;
  }
}

function onEmailInput() {
  if (isEmailValid.value) {
    email.value = email.value.trim().toLowerCase();
    stage.value = Stage.PasswordInput;
  }

  isWorking.value = false;
}

async function onLoginAttempt() {
  const { result: loginResult, tenants: availableTenants } = await signInWithEmail({
    email: email.value,
    password: password.value,
    tenantId: selectedTenantId.value === "" ? undefined : selectedTenantId.value,
    ...(stage.value === Stage.TOTP
      ? { totpToken: totpToken.value, isMfaDeviceTokenRequested: rememberDevice.value }
      : {}),
  });

  isWorking.value = false;

  switch (loginResult) {
    case SessionCreateResult.Success:
      isWorking.value = false;
      await followRedirect();
      return;

    case SessionCreateResult.Failure:
      if (stage.value === Stage.TOTP) {
        totpToken.value = "";
        totpInputFocused.value = true;
      } else {
        password.value = "";
        document.querySelector<HTMLInputElement>("input[type=password]")?.focus();
      }
      break;

    case SessionCreateResult.TenantSelectionRequired:
      stage.value = Stage.TenantSelection;
      tenants.value = availableTenants ?? []; // backend will always return tenants here
      selectedTenantId.value = tenants.value[0].id;
      break;

    case SessionCreateResult.TOTPRequired:
      stage.value = Stage.TOTP;
      break;

    default:
      break;
  }

  isWorking.value = false;
}

async function onPasswordReset() {
  await onRequestPasswordReset();
  isWorking.value = false;
}

function onEmailSent() {
  stage.value = Stage.PasswordInput;
  password.value = "";
  document.querySelector<HTMLInputElement>("input[type=password]")?.focus();
  isWorking.value = false;
}

async function onRequestPasswordReset(): Promise<void> {
  try {
    await axios.post("/api/users/password-reset-email", { email: email.value });
    stage.value = Stage.PasswordResetEmailSent;
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Password reset request failed",
    });
    return;
  } finally {
    emailInputFocused.value = true;
  }
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instructions {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: bold;
  align-self: center;

  svg {
    color: var(--confirm-color-2);
  }
}

.auth-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.sso-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sso-divider {
  display: flex;
  align-items: center;

  margin-top: 8px;
  margin-bottom: 16px;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--border-color-1);
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }
}
</style>
