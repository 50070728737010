<template>
  <StudyShareHistoryModal
    v-if="isStudyShareHistoryModalVisible"
    :study-id="studyId"
    @close="isStudyShareHistoryModalVisible = false"
  />

  <StudyShareSavedRecipientsModal
    v-else-if="isSavedRecipientsModalVisible"
    :study-id="studyId"
    :recipients="savedShareLinkRecipients"
    @remove-recipient="(id) => removeRecipient(id)"
    @close="isSavedRecipientsModalVisible = false"
  />

  <Modal
    v-else
    title="Share Study"
    :activity-text="activityText"
    @header-button-click="emits('close')"
  >
    <div class="study-share-modal">
      <template v-if="studyShareLink.token === ''">
        <div class="field">
          <strong>Recipient name</strong>

          <DropdownInput
            :current-item="recipientName"
            :items="savedShareLinkRecipients.map((r) => r.name).sort((a, b) => a.localeCompare(b))"
            :show="showDropdownInputList"
            @select-item="(item: string) => (recipientName = item)"
          >
            <input
              ref="recipientNameInputElement"
              v-model="recipientName"
              style="height: 16px; width: 422px"
              type="text"
              placeholder="Dr Christiaan Barnard"
              data-testid="study-share-link-recipient-name"
              @keydown.enter="createStudyShareLink"
            />
          </DropdownInput>
        </div>

        <div class="footer">
          <div />
          <button
            :disabled="recipientName === ''"
            data-testid="create-study-share-link"
            class="accented"
            style="width: max-content; margin: 0 auto"
            @click="createStudyShareLink"
          >
            Create Share Link
          </button>

          <div class="buttons">
            <div />

            <IconButton
              icon="address-book"
              size="lg"
              tooltip="Manage saved recipients"
              @click="isSavedRecipientsModalVisible = true"
            />

            <IconButton
              icon="clock-rotate-left"
              size="lg"
              tooltip="View sharing history"
              @click="isStudyShareHistoryModalVisible = true"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="share-methods">
          <button :class="{ active: currentTab === 'link' }" @click="currentTab = 'link'">
            Link
          </button>
          <button
            :class="{ active: currentTab === 'email' }"
            data-testid="share-by-email"
            @click="currentTab = 'email'"
          >
            Email
          </button>
        </div>

        <template v-if="currentTab === 'link'">
          <div class="field">
            <strong>Share link</strong>
            <div style="display: flex; gap: 4px">
              <input
                readonly
                data-testid="created-study-share-link"
                :value="studyShareLink.url"
                style="flex: 1"
              />
              <Tooltip content="Copy share link to clipboard">
                <button @click="copyTextToClipboard(studyShareLink.url, 'study share link')">
                  <FontAwesomeIcon icon="copy" size="lg" />
                </button>
              </Tooltip>
            </div>
            <span style="font-size: 0.9em">Link expires in 7 days</span>
          </div>

          <div class="field">
            <strong>Passcode</strong>
            <div style="display: flex; gap: 4px">
              <input
                readonly
                data-testid="created-study-share-passcode"
                :value="studyShareLink.passcode"
                style="flex: 1; font-weight: bold"
              />
              <Tooltip content="Copy passcode to clipboard">
                <button @click="copyTextToClipboard(studyShareLink.passcode, 'passcode')">
                  <FontAwesomeIcon icon="copy" size="lg" />
                </button>
              </Tooltip>
            </div>
            <span style="font-size: 0.9em">
              This passcode is required in order to access the share link
            </span>
          </div>
        </template>
        <template v-else-if="currentTab === 'email'">
          <div class="field">
            <b>Email address</b>
            <input
              ref="emailAddressInputElement"
              v-model="emailAddress"
              data-testid="recipient-email"
              placeholder="example@heartlab.com"
              :disabled="isRecipientSaved"
            />
          </div>

          <Checkbox
            v-if="!isRecipientSaved"
            v-model="shouldSaveRecipient"
            data-testid="save-recipient-checkbox"
          >
            Save this recipient for future use
          </Checkbox>

          <div class="field">
            <b>Message</b>
            <textarea
              v-model="emailMessage"
              placeholder="Message to include in the email with the share link email"
            />
          </div>

          <button
            class="accented"
            style="margin: 0 auto"
            data-testid="send-study-share-link-email"
            :disabled="!isEmailValid"
            @click="sendStudyShareLinkEmail"
          >
            Send Email
          </button>
        </template>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import DropdownInput from "@/components/DropdownInput.vue";
import Modal from "@/components/Modal.vue";
import StudyShareHistoryModal from "@/components/StudyShareHistoryModal.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useClipboard, useFocus } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { computed, reactive, ref } from "vue";
import type { StudyShareLinkCreateResponseDto } from "../../../backend/src/studies/sharing/dto/study-share-link-create.dto";
import type { StudyShareLinkRecipientGetManyResponseDto } from "../../../backend/src/studies/sharing/dto/study-share-link-recipient-get-many.dto";
import Checkbox from "./Checkbox.vue";
import IconButton from "./IconButton.vue";
import StudyShareSavedRecipientsModal from "./StudyShareSavedRecipientsModal.vue";
import Tooltip from "./Tooltip.vue";

interface Props {
  studyId: string;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isSavedRecipientsModalVisible = ref(false);
const isStudyShareHistoryModalVisible = ref(false);

const selectedRecipientId = ref("");
const savedShareLinkRecipients = ref<StudyShareLinkRecipientGetManyResponseDto>([]);

const shouldSaveRecipient = ref(true);
const isRecipientSaved = ref(false);

const recipientName = ref("");
const studyShareLink = reactive({ id: "", token: "", passcode: "", url: "" });
const activityText = ref("");

const recipientNameInputElement = ref<HTMLInputElement>();
useFocus(recipientNameInputElement, { initialValue: true });

const emailAddressInputElement = ref<HTMLInputElement>();
useFocus(emailAddressInputElement, { initialValue: true });

const showDropdownInputList = computed(() => recipientName.value !== "");

async function getSavedShareLinkRecipients(): Promise<void> {
  activityText.value = "Loading...";

  try {
    savedShareLinkRecipients.value = (
      await axios.get<StudyShareLinkRecipientGetManyResponseDto>(
        `/api/studies/${props.studyId}/share-links/saved-recipients`
      )
    ).data;
  } catch {
    addNotification({ type: "error", message: "Failed fetching saved recipients" });
  } finally {
    activityText.value = "";
  }
}

async function removeRecipient(id: string) {
  try {
    await axios.delete(`/api/studies/${props.studyId}/share-links/saved-recipients/${id}`);

    savedShareLinkRecipients.value = savedShareLinkRecipients.value.filter((r) => r.id !== id);

    addNotification({
      type: "info",
      message: "Recipient removed successfully",
    });
  } catch {
    addNotification({
      type: "error",
      message: "Failed to remove recipient",
    });
  }
}

async function createStudyShareLink(): Promise<void> {
  if (studyShareLink.token !== "") {
    return;
  }

  activityText.value = "Creating share link";

  let response: AxiosResponse<StudyShareLinkCreateResponseDto> | undefined = undefined;

  try {
    const savedRecipientForName = savedShareLinkRecipients.value.find(
      (r) => r.name === recipientName.value
    );

    const body =
      savedRecipientForName !== undefined
        ? { recipientId: savedRecipientForName.id }
        : { recipientName: recipientName.value };

    response = await axios.post<StudyShareLinkCreateResponseDto>(
      `/api/studies/${props.studyId}/share-links`,
      body
    );
  } catch (error) {
    addNotification({ type: "error", message: "Failed creating share link" });
    return;
  } finally {
    activityText.value = "";
  }

  studyShareLink.id = response.data.id;
  studyShareLink.token = response.data.token;
  studyShareLink.passcode = response.data.passcode;
  studyShareLink.url = response.data.url;
  selectedRecipientId.value = response.data.recipientId;

  if (response.data.recipientSaved && response.data.recipientEmail !== null) {
    isRecipientSaved.value = true;
    emailAddress.value = response.data.recipientEmail;
    currentTab.value = "email";
  }
}

const currentTab = ref<"email" | "link">("link");

const { copy, isSupported: isClipboardSupported } = useClipboard();

async function copyTextToClipboard(content: string, description: string): Promise<void> {
  if (!isClipboardSupported.value) {
    addNotification({ type: "info", message: "Unable to copy text to the clipboard" });
    return;
  }

  await copy(content);

  addNotification({ type: "info", message: `Copied ${description} to the clipboard` });
}

const emailAddress = ref("");
const emailMessage = ref(
  "Could you please review this study and let me know what you think?\n\nThanks."
);

const isEmailValid = computed(() =>
  emailAddress.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
);

async function sendStudyShareLinkEmail(): Promise<void> {
  activityText.value = "Sending email";

  try {
    await axios.post(`/api/studies/${props.studyId}/share-links/${studyShareLink.id}/send-email`, {
      token: studyShareLink.token,
      passcode: studyShareLink.passcode,
      emailAddress: emailAddress.value,
      message: emailMessage.value,
    });

    if (shouldSaveRecipient.value && selectedRecipientId.value !== "") {
      await axios.post(
        `/api/studies/${props.studyId}/share-links/saved-recipients/${selectedRecipientId.value}/save`,
        {
          email: emailAddress.value,
        }
      );

      await getSavedShareLinkRecipients();
    }
  } catch (error) {
    addNotification({ type: "error", message: "Failed sending email" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Sent email to ${emailAddress.value}` });
}

void getSavedShareLinkRecipients();
</script>

<style scoped lang="scss">
.study-share-modal {
  width: 440px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.share-methods {
  display: flex;
  justify-content: center;

  > * {
    width: 70px;

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
    &:last-child {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input,
  textarea {
    background: var(--bg-color-3);
    resize: none;
  }

  textarea {
    height: 120px;
    line-height: 1.4em;
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 12px;
}
</style>
