import type { Ref } from "vue";
import {
  GlobalAdminPermission,
  Permission,
} from "../../../backend/src/auth/permissions/permissions";

export interface PermissionGroupBase<T> {
  type: string;
  name: string;
  description?: string;
  permissions: T[];
}

export interface PermissionGroup extends PermissionGroupBase<PermissionInfo> {
  type: "user";
  permissions: PermissionInfo[];
}

export interface AdminPermissionGroup extends PermissionGroupBase<AdminPermissionInfo> {
  type: "admin";
  permissions: AdminPermissionInfo[];
}

export interface ApiKeyPermissionGroup extends PermissionGroupBase<ApiKeyPermissionInfo> {
  type: "api-key";
  permissions: ApiKeyPermissionInfo[];
}

export interface PermissionInfoBase<P> {
  type: string;
  value: P;
  requires?: P[];
  name?: string;
  description?: string;
}

export interface PermissionInfo extends PermissionInfoBase<Permission> {
  type: "user";
}
export interface AdminPermissionInfo extends PermissionInfoBase<GlobalAdminPermission> {
  type: "admin";
}
export interface ApiKeyPermissionInfo extends PermissionInfoBase<Permission> {
  type: "api-key";
}

export const PERMISSION_GROUPS: PermissionGroup[] = [
  {
    name: "Activity Charts",
    description: "Viewing of activity charts",
    permissions: [
      {
        name: "View",
        value: Permission.ChartingView,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Audit Logs",
    description: "Viewing and searching of audit logs",
    permissions: [
      {
        value: Permission.AuditLogView,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Billing",
    description: "Access to billing information",
    permissions: [
      {
        value: Permission.BillingManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "DICOM Endpoints",
    permissions: [
      {
        name: "Query",
        description: "Querying of DICOM endpoints and importing of found studies",
        value: Permission.DicomEndpointQuery,
        type: "user",
      },
      {
        name: "Send",
        description: "Initiate sending of studies to DICOM endpoints",
        value: Permission.DicomEndpointSend,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "DICOM Worklist Entries",
    description: "Management of DICOM worklist entries",
    permissions: [
      {
        value: Permission.DicomScheduledProcedureStepManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Integrations",
    permissions: [
      {
        name: "API Key",
        description: "Management of API keys",
        value: Permission.ApiKeyIntegrationManage,
        type: "user",
      },
      {
        name: "Agent",
        description: "Management of HeartLab Agent integrations",
        value: Permission.AgentIntegrationManage,
        type: "user",
      },
      {
        name: "DICOM",
        description: "Management of DICOM client identities used by connected modalities",
        value: Permission.DicomClientIdentityManage,
        type: "user",
      },
      {
        name: "HeartBox",
        description: "Management of HeartBox integrations",
        value: Permission.HeartBoxIntegrationManage,
        type: "user",
      },
      {
        name: "HL7",
        description: "Management of HL7 integrations for automated report sending",
        value: Permission.HL7IntegrationManage,
        type: "user",
      },
      {
        name: "HL7 Message Download",
        description: "Downloading of HL7 messages as a file",
        value: Permission.HL7MessageDownload,
        type: "user",
      },
      {
        name: "HL7 Message Send",
        description: "Sending of study reports to a configured HL7 integration",
        value: Permission.HL7MessageSend,
        type: "user",
      },
      {
        name: "SFTP",
        description: "Management of SFTP integrations for automated report sending",
        value: Permission.SftpIntegrationManage,
        type: "user",
      },
      {
        name: "Webhooks",
        description: "Management of webhooks used by 3rd party integrations",
        value: Permission.WebhookIntegrationManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Measurement Calculations",
    description: "Management of measurement calculations",
    permissions: [
      {
        value: Permission.MeasurementCalculationManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Measurement Sequences",
    description: "Management of measurement sequences",
    permissions: [
      {
        value: Permission.MeasurementSequenceManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Report Templates",
    description: "Management of report templates and the sentence library",
    permissions: [
      {
        value: Permission.ReportTemplateManage,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Studies",
    permissions: [
      {
        name: "View",
        description: "Viewing of studies",
        value: Permission.StudyView,
        type: "user",
      },
      {
        name: "Edit",
        description: "Editing of studies and measurements",
        value: Permission.StudyUpdate,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Delete study",
        description: "Deletion of whole studies",
        value: Permission.StudyDelete,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Delete clips",
        description: "Deletion of individual clips in a study",
        value: Permission.StudyClipDelete,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Change patient identity",
        description: "Changing the patient identity for studies",
        value: Permission.StudyPatientIdentityUpdate,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Reprocess",
        description: "Manual reprocessing of studies (use with caution)",
        value: Permission.StudyReprocess,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Share",
        description: "Sharing of studies using a share link",
        value: Permission.StudyShare,
        requires: [Permission.StudyView],
        type: "user",
      },
      {
        name: "Upload",
        description:
          "Uploading of DICOM files that may create new studies or add to existing studies",
        value: Permission.StudyUpload,
        type: "user",
      },
      {
        name: "Webhook activate",
        description: "Requesting to send a study to a webhook integration",
        value: Permission.StudyWebhookActivate,
        requires: [Permission.StudyView],
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Study Reports",
    permissions: [
      {
        name: "Create initial preliminary",
        description: "Creating the initial preliminary report",
        value: Permission.StudyReportPreliminaryCreateInitial,
        type: "user",
      },
      {
        name: "Create preliminary",
        description: "Creating preliminary reports",
        value: Permission.StudyReportPreliminaryCreate,
        requires: [Permission.StudyReportPreliminaryCreateInitial],
        type: "user",
      },
      {
        name: "Approve preliminary",
        description: "Approving preliminary reports",
        value: Permission.StudyReportPreliminaryComplete,
        requires: [
          Permission.StudyReportPreliminaryCreateInitial,
          Permission.StudyReportPreliminaryCreate,
        ],
        type: "user",
      },
      {
        name: "Finalize preliminary",
        description: "Finalizing preliminary reports by signing it off",
        value: Permission.StudyReportPreliminaryFinalize,
        requires: [
          Permission.StudyReportPreliminaryCreateInitial,
          Permission.StudyReportPreliminaryCreate,
        ],
        type: "user",
      },
      {
        name: "Create amendments",
        description: "Creating report amendments",
        value: Permission.StudyReportAmendmentCreate,
        type: "user",
      },
      {
        name: "Approve simple amendments",
        description:
          "Approving simple report amendments that do not change the body of the report, e.g. when only the patient details have changed",
        value: Permission.StudyReportAmendmentComplete,
        requires: [Permission.StudyReportAmendmentCreate],
        type: "user",
      },
      {
        name: "Approve amendments to report body",
        description: "Approving report amendments that change any of the report's content",
        value: Permission.StudyReportAmendmentCompleteWithUpdatedContent,
        requires: [Permission.StudyReportAmendmentCreate, Permission.StudyReportAmendmentComplete],
        type: "user",
      },
      {
        name: "Delete unsigned reports",
        description: "Deleting reports that have not yet been signed off",
        value: Permission.StudyReportDeleteUnsigned,
        type: "user",
      },
      {
        name: "Delete signed reports",
        description: "Deleting reports that have been finalized or signed off",
        value: Permission.StudyReportDeleteSigned,
        requires: [Permission.StudyReportDeleteUnsigned],
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Study List",
    permissions: [
      {
        name: "View",
        description: "Viewing of the study list",
        value: Permission.StudyListView,
        type: "user",
      },
      {
        name: "Export",
        description: "CSV exporting of the study list",
        value: Permission.StudyListExport,
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Users",
    permissions: [
      {
        name: "View",
        description: "Viewing of users and user details",
        value: Permission.UserView,
        requires: [Permission.UserRoleView],
        type: "user",
      },
      {
        name: "Invite",
        description: "Inviting of users by email and canceling of pending invitations",
        value: Permission.UserInvite,
        requires: [Permission.UserView],
        type: "user",
      },
      {
        name: "Update",
        description: "Updating of the details for users, exclusive of roles",
        value: Permission.UserUpdate,
        requires: [Permission.UserView],
        type: "user",
      },
      {
        name: "Update roles",
        description: "Updating of the roles assigned to users",
        value: Permission.UserRolesUpdate,
        requires: [Permission.UserView],
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "User Roles",
    permissions: [
      {
        name: "View",
        description: "Viewing of the roles that can be assigned to users",
        value: Permission.UserRoleView,
        type: "user",
      },
      {
        name: "Manage",
        description: "Management of the roles that can be assigned to users",
        value: Permission.UserRoleManage,
        requires: [Permission.UserRoleView],
        type: "user",
      },
    ],
    type: "user",
  },
  {
    name: "Other Admin Tasks",
    description: "Management of global settings not covered by other permissions",
    permissions: [
      {
        value: Permission.TenantManage,
        type: "user",
      },
    ],
    type: "user",
  },
];

export const GLOBAL_ADMIN_PERMISSION_GROUPS: AdminPermissionGroup[] = [
  {
    name: "Global Admin Roles",
    permissions: [
      {
        name: "Manage",
        value: GlobalAdminPermission.RoleManage,
        description: "Management of global admin roles",
        type: "admin",
      },
      {
        name: "Update permissions",
        value: GlobalAdminPermission.RolePermissionsUpdate,
        description: "Updating of permissions for global admin roles",
        type: "admin",
      },
    ],
    type: "admin",
  },
  {
    name: "Tenant Management",
    permissions: [
      {
        name: "Create",
        value: GlobalAdminPermission.TenantCreate,
        description: "Creating a new tenant",
        type: "admin",
      },
      {
        name: "View",
        value: GlobalAdminPermission.TenantView,
        description: "Viewing of tenant details",
        type: "admin",
      },
      {
        name: "Update",
        value: GlobalAdminPermission.TenantUpdate,
        description: "Updating of tenant details",
        type: "admin",
      },
      {
        name: "Tenant Admin Login",
        value: GlobalAdminPermission.TenantAdminLogin,
        description: "Logging into a tenant account as a user with specific roles",
        type: "admin",
      },
      {
        name: "DICOM Endpoint Management",
        value: GlobalAdminPermission.DicomEndpointManage,
        description: "Configuration of DICOM endpoints",
        type: "admin",
      },
    ],
    type: "admin",
  },
  {
    name: "Billing",
    permissions: [
      {
        name: "View",
        value: GlobalAdminPermission.BillingView,
        description: "Viewing of tenant billing information",
        type: "admin",
      },
      {
        name: "Update",
        value: GlobalAdminPermission.BillingUpdate,
        description: "Updating of tenant billing information",
        type: "admin",
      },
    ],
    type: "admin",
  },
];

export const API_KEY_PERMISSION_GROUPS: ApiKeyPermissionGroup[] = [
  {
    name: "Study",
    permissions: [
      {
        name: "View",
        value: Permission.StudyView,
        description: "Access of study data, including image data and measurements",
        type: "api-key",
      },
      {
        name: "Update",
        value: Permission.StudyUpdate,
        description: "Updating of study data, including creating measurements & remarks",
        type: "api-key",
      },
      {
        name: "Upload",
        value: Permission.StudyUpload,
        description: "Uploading of new studies & creation of presigned URL for uploads",
        type: "api-key",
      },
    ],
    type: "api-key",
  },
  {
    name: "Study List",
    description: "Access of the study list information",
    permissions: [
      {
        name: "Access",
        value: Permission.StudyListView,
        type: "api-key",
      },
    ],
    type: "api-key",
  },
  {
    name: "Worklist",
    description:
      "Management of DICOM worklist entries, including viewing and updating, and deletion",
    permissions: [
      {
        name: "Manage",
        value: Permission.DicomScheduledProcedureStepManage,
        type: "api-key",
      },
    ],
    type: "api-key",
  },
  {
    name: "Chart",
    description: "Access of the activity chart data",
    permissions: [
      {
        name: "Access",
        value: Permission.ChartingView,
        type: "api-key",
      },
    ],
    type: "api-key",
  },
];

export function usePermissionToggle<
  Perm extends Permission | GlobalAdminPermission,
  Info extends PermissionInfoBase<Perm>,
  Group extends PermissionGroupBase<Info>,
>(role: Ref<{ permissions: Perm[] } | undefined>, groups: Group[]) {
  function onPermissionGroupToggle(newValue: boolean, group: Group) {
    if (role.value === undefined) {
      return;
    }

    for (const permission of group.permissions) {
      if (newValue) {
        role.value.permissions.push(permission.value);

        // Add permissions that are required by this permission
        for (const requiredPermission of permission.requires ?? []) {
          if (!role.value.permissions.includes(requiredPermission)) {
            role.value.permissions.push(requiredPermission);
          }
        }
      } else {
        role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);
      }
    }
  }

  function onPermissionToggle(newValue: boolean, permission: Info): void {
    if (role.value === undefined) {
      return;
    }

    if (newValue) {
      role.value.permissions.push(permission.value);

      // Add permissions that are required by this permission
      for (const requiredPermission of permission.requires ?? []) {
        if (!role.value.permissions.includes(requiredPermission)) {
          role.value.permissions.push(requiredPermission);
        }
      }
    } else {
      role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);

      // Remove permissions that require this permission
      for (const group of groups) {
        for (const permissionInfo of group.permissions) {
          if ((permissionInfo.requires ?? []).includes(permission.value)) {
            role.value.permissions = role.value.permissions.filter(
              (p) => p !== permissionInfo.value
            );
          }
        }
      }
    }
  }

  return { onPermissionToggle, onPermissionGroupToggle };
}
