<template>
  <Popper
    placement="top-start"
    class="measurement-tool-popper"
    :offset-distance="2"
    @open="isMeasurementToolPopperOpen = true"
    @close="isMeasurementToolPopperOpen = false"
  >
    <Tooltip content="Take a measurement" :visible="!isMeasurementToolPopperOpen">
      <button
        data-testid="open-measurement-popper-btn"
        :class="{ active: isMeasurementToolPopperOpen }"
      >
        <FontAwesomeIcon icon="ruler" />
      </button>
    </Tooltip>

    <template #content>
      <div class="measurement-tool-groups">
        <div
          v-for="toolGroup in measurementTools"
          :key="toolGroup.name"
          class="measurement-tool-group"
        >
          <b>{{ toolGroup.name }} Tools</b>
          <div
            v-for="(toolRow, toolRowIndex) in toolGroup.rows"
            :key="toolRowIndex"
            class="measurement-tool-row"
          >
            <Tooltip
              v-for="tool in toolRow"
              :key="tool.toolName"
              :data-testid="`${displayNameForTool[tool.toolName].replace(' ', '-').toLowerCase()}-measurement-btn`"
              content="This measurement tool can't be used on any of the visible clips"
              :visible="!tool.enabled"
              @click="beginMeasurement(tool)"
            >
              <div class="measurement-tool-option" :class="{ disabled: !tool.enabled }">
                {{ displayNameForTool[tool.toolName] }}
              </div>
            </Tooltip>
          </div>
        </div>

        <Popper
          v-if="validSequences.length > 0"
          class="sequences-popper"
          :offset-distance="6"
          placement="right-end"
          @open="isSequencesPopperVisible = true"
          @close="isSequencesPopperVisible = false"
        >
          <div
            class="sequences-button"
            :class="{ active: isSequencesPopperVisible }"
            data-testid="open-sequences-button"
          >
            <b>Measurement Sequences</b>
            <FontAwesomeIcon style="margin-left: auto" icon="chevron-right" />
          </div>

          <template #content>
            <div
              v-for="sequence in validSequences"
              :key="sequence.id"
              class="measurement-tool-option"
              style="min-width: auto; width: max-content; max-width: 300px"
              :data-testid="`${sequence.name.replace(' ', '-').toLowerCase()}-mmt-sequence-btn`"
              @click="beginMeasurementSequence(study, sequence.id, measurementSequenceList)"
            >
              {{ sequence.name }}
            </div>
          </template>
        </Popper>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { MeasurementToolName } from "../../../backend/src/measurements/measurement-tool-names";
import Tooltip from "../components/Tooltip.vue";
import { getEnabledMeasurementTools } from "../measurements/measurement-tool-helpers";
import { beginMeasurementSequence, startMeasuring } from "../measurements/measurement-tool-state";
import { removeNullish } from "../utils/array-helpers";
import type { Study } from "../utils/study-data";
import {
  ClipsGridItem,
  ClipsGridItemType,
  getRegularClipsGridItems,
} from "./clip-viewer/clips-grid-item";
import { useMeasurementSequenceList } from "./measurement-sequence-list";

interface Props {
  study: Study;
  clipsGridItems: ClipsGridItem[];
}

const props = defineProps<Props>();

const isMeasurementToolPopperOpen = ref(false);

/** Describes a single measurement tool that is made available in the application. */
interface MeasurementToolDescription {
  toolName: MeasurementToolName;
  enabled: boolean;
}

const visibleClips = computed(() => removeNullish(props.clipsGridItems.map((model) => model.clip)));

const isNonCTModeClipOpen = computed(() =>
  props.clipsGridItems.some((item) => item.type === ClipsGridItemType.RegularClip)
);

const displayNameForTool: Record<MeasurementToolName, string> = {
  [MeasurementToolName.Distance]: "Distance",
  [MeasurementToolName.Area]: "Area",
  [MeasurementToolName.Volume]: "Volume",
  [MeasurementToolName.Angle]: "Angle",
  [MeasurementToolName.EjectionFraction]: "Ejection Fraction",
  [MeasurementToolName.Velocity]: "Velocity",
  [MeasurementToolName.Time]: "Time",
  [MeasurementToolName.VelocityTimeIntegral]: "VTI",
  [MeasurementToolName.DopplerSlope]: "Slope",
  [MeasurementToolName.MModeSlope]: "M-Mode Slope",
  [MeasurementToolName.Displacement]: "M-Mode Displacement",
};

const measurementTools = computed(() => {
  const tools = getEnabledMeasurementTools(isNonCTModeClipOpen.value, visibleClips.value);

  return [
    {
      name: "2D",
      rows: [
        Object.entries(tools)
          .filter(([toolName]) =>
            [
              MeasurementToolName.Distance,
              MeasurementToolName.Area,
              MeasurementToolName.Volume,
              MeasurementToolName.Angle,
            ].includes(toolName as MeasurementToolName)
          )
          .map(([toolName, enabled]) => ({
            toolName: toolName as MeasurementToolName,
            enabled,
          })),
        Object.entries(tools)
          .filter(([toolName]) =>
            [MeasurementToolName.EjectionFraction].includes(toolName as MeasurementToolName)
          )
          .map(([toolName, enabled]) => ({
            toolName: toolName as MeasurementToolName,
            enabled,
          })),
      ],
    },
    {
      name: "Doppler",
      rows: [
        Object.entries(tools)
          .filter(([toolName]) =>
            [
              MeasurementToolName.Velocity,
              MeasurementToolName.Time,
              MeasurementToolName.VelocityTimeIntegral,
              MeasurementToolName.DopplerSlope,
            ].includes(toolName as MeasurementToolName)
          )
          .map(([toolName, enabled]) => ({
            toolName: toolName as MeasurementToolName,
            enabled,
          })),
        Object.entries(tools)
          .filter(([toolName]) =>
            [MeasurementToolName.MModeSlope, MeasurementToolName.Displacement].includes(
              toolName as MeasurementToolName
            )
          )
          .map(([toolName, enabled]) => ({
            toolName: toolName as MeasurementToolName,
            enabled,
          })),
      ],
    },
  ];
});

function beginMeasurement(tool: MeasurementToolDescription): void {
  if (!tool.enabled) {
    return;
  }

  startMeasuring({ tool: tool.toolName, study: props.study, clipId: "", informOtherWindows: true });

  const regularGridItems = getRegularClipsGridItems(props.clipsGridItems);
  for (const item of regularGridItems) {
    item.isPlaying.value = false;
  }
}

//
// Measurement sequences
//

const isSequencesPopperVisible = ref(false);

const measurementSequenceList = useMeasurementSequenceList();

const validSequences = computed(() =>
  measurementSequenceList.value
    .map((s) => ({
      ...s,
      steps: s.steps.filter(
        (step) => step.measurementTool !== null && step.measurementName !== null
      ),
    }))
    .filter((sequence) => sequence.steps.length !== 0)
);
</script>

<style scoped lang="scss">
:deep(.measurement-tool-popper) {
  background-color: var(--bg-color-4);
  box-shadow: none;
  z-index: 15;
}

.measurement-tool-groups {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.measurement-tool-group {
  display: flex;
  flex-direction: column;
  gap: 2px;

  b {
    margin-bottom: 4px;
  }
}

.measurement-tool-row {
  display: flex;
  gap: 4px;
}

.measurement-tool-option {
  padding: 4px 8px;
  cursor: pointer;
  background-color: var(--bg-color-3);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  min-width: max-content;
  transition: filter 100ms ease;

  &:hover {
    filter: brightness(200%);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.sequences-button {
  display: flex;
  cursor: pointer;
  transition: color 100ms ease;

  &.active,
  &:hover {
    color: var(--text-color-2);
  }
}

:deep(.sequences-popper) {
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: var(--bg-color-4);
  padding: 4px;
  transform: translateY(8.5px);
}
</style>
