<template>
  <Tooltip :content="!disabled ? tooltip : ''">
    <div
      class="icon-button"
      :class="[{ highlight, disabled, 'icon-only': !$slots.default }, type]"
      @click="(event: MouseEvent) => emits('click', event)"
    >
      <FontAwesomeIcon v-if="!toggleOptions" :icon="icon" :size="size" v-bind="iconProps" />
      <Chevron
        v-else
        :icon="icon"
        :size="size"
        :is-dropdown-open="toggleOptions?.isOpen ?? false"
        :invert-rotation="toggleOptions?.invertRotation ?? false"
        v-bind="iconProps"
      />
      <span v-if="$slots.default" class="button-text">
        <slot />
      </span>
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
/**
 * A flexible button component that renders as a fontawesome icon and emits a click event.
 * Optionally, a slot can be used to render text next to the icon.
 *
 * This also implements various button styles used throughout our design system.
 */
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { toRefs } from "vue";
import Chevron from "./ui/chevron/Chevron.vue";

interface Props {
  /**
   * The fontawesome icon to display. Check icons.ts for the list of exported icons.
   */
  icon: string;
  /**
   * The tooltip to display.
   */
  tooltip?: string;
  /**
   * If true, the button will be disabled.
   */
  disabled?: boolean;

  /**
   * The style type of the icon.
   *
   * The rounded styles have a slight border radius only visible with either solid
   * or highlight prop enabled.
   */
  type?: "solid-rounded" | "regular-rounded" | "solid-square" | "regular-square" | "more-icon";

  /**
   * The size of the icon.
   */
  size?:
    | "2xs"
    | "xs"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";

  /**
   * If true, the button container div will be highlighted
   */
  highlight?: boolean;

  toggleOptions?: {
    /**
     * If true, the button icon spins 180 degrees when open. Useful if this icon button is used to open or close a dropdown.
     */
    isOpen: boolean;
    /**
     * If true, the chevron will rotate 180 degrees clockwise instead of 180 degrees anti-clockwise.
     */
    invertRotation?: boolean;
  };
}

interface Emits {
  (event: "click", mouseEvent: MouseEvent): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { icon, tooltip, toggleOptions, disabled, size, ...iconProps } = toRefs(props);
</script>

<style scoped lang="scss">
.icon-button {
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: color 100ms ease;

  &:hover:not(.disabled) {
    color: var(--text-color-2);
  }

  &.solid-rounded {
    border-radius: var(--border-radius);
    background-color: var(--bg-color-3);
  }

  &.regular-rounded {
    border-radius: var(--border-radius);
  }

  &.solid-square {
    border-radius: 0;
    background-color: var(--bg-color-3);
  }

  &.regular-square {
    border-radius: 0;
  }

  &.more-icon {
    border: 1px solid var(--border-color-1);
    border-radius: 2px;
    padding: 9px;

    &:hover {
      background-color: var(--bg-color-4);
      color: var(--text-color-2);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &.highlight {
    padding: 4px 8px;
    transition:
      color 100ms ease,
      background-color 100ms ease;

    &:hover {
      background-color: var(--bg-color-4);
      color: var(--text-color-2);
    }

    &.icon-only {
      display: grid;
      place-content: center;
      height: 26px;
      width: 26px;
      padding: 0;
    }
  }

  .button-text {
    margin-left: 6px;
  }
}
</style>
