<template>
  <div class="measurement-pane" data-testid="measurement-pane">
    <RemarkCard v-for="remark in study.remarks" :key="remark.id" :remark="remark" />

    <div v-if="expanded" class="measurement-content">
      <Tabs v-model="activeTab" :style="{ flex: measurements.length + 1, minHeight: '0' }">
        <div class="toolbar">
          <div class="tabs-list-container">
            <TabsList>
              <TabsTrigger
                value="alphabetical"
                data-testid="measurement-pane-abc-tab"
                :disabled="filteredAlphabeticalMeasurements.length === 0"
              >
                Abc
              </TabsTrigger>
              <TabsTrigger
                value="structure"
                data-testid="measurement-pane-structure-tab"
                :disabled="filteredGroupedMeasurements.structures.length === 0"
              >
                Structure
              </TabsTrigger>
              <TabsTrigger
                value="function"
                data-testid="measurement-pane-function-tab"
                :disabled="filteredGroupedMeasurements.functions.length === 0"
              >
                Function
              </TabsTrigger>
            </TabsList>
            <div class="toolbar-buttons">
              <IconButton
                data-testid="measurement-pane-expand-collapse-button"
                icon="chevrons-up"
                size="sm"
                highlight
                :type="'regular-rounded'"
                :toggle-options="{ isOpen: isAccordionsExpanded, invertRotation: true }"
                :tooltip="isAccordionsExpanded ? 'Collapse all' : 'Expand all'"
                :disabled="
                  activeTab === TabType.Alphabetical ||
                  (filteredGroupedMeasurements.structures.length === 0 &&
                    filteredGroupedMeasurements.functions.length === 0)
                "
                @click="collapseExpandAccordions"
              />
              <AddCustomMeasurementButton
                v-if="studyUpdatePermitted"
                :study="study"
                :is-add-custom-measurement-card-visible="isAddCustomMeasurementCardVisible"
                :measurement="customTypedMeasurement"
                @open="isAddCustomMeasurementCardVisible = true"
                @close="
                  () => {
                    isAddCustomMeasurementCardVisible = false;
                    customTypedMeasurement = undefined;
                  }
                "
              />
            </div>
          </div>

          <div>
            <FilterInput
              :model-value="searchTerm"
              placeholder="Search measurements"
              show-margin
              blur-on-enter-press
              style="border-bottom: 1px solid var(--border-color-1); flex: 1"
              data-testid="measurement-pane-search-input"
              @update:model-value="(newValue) => emits('update:searchTerm', newValue)"
            />
          </div>
        </div>

        <OverlayScrollbar :scroll-target="cardToScrollTo">
          <TabsContent value="alphabetical">
            <AlphabeticalTab
              :measurements="filteredAlphabeticalMeasurements"
              :groups="filteredGroupedMeasurements.functions"
              :opened-groups="openedStructureGroups"
              v-bind="commonTabProps"
              @update:custom-typed-measurement-name="createCustomTypedMeasurement($event)"
              @update:is-measurement-card-expanded="updateMeasurementCardExpanded"
              @update:measurement-display-option="updateMeasurementDisplayOption"
              @take-measurement="takeMeasurement"
            />
          </TabsContent>
          <TabsContent value="structure" class="tab-content">
            <GroupedTab
              :type="TabType.Structure"
              :groups="filteredGroupedMeasurements.structures"
              :opened-groups="openedStructureGroups"
              v-bind="commonTabProps"
              @update:opened-groups="openedStructureGroups = $event"
              @update:custom-typed-measurement-name="createCustomTypedMeasurement($event)"
              @update:is-measurement-card-expanded="updateMeasurementCardExpanded"
              @update:measurement-display-option="updateMeasurementDisplayOption"
              @take-measurement="takeMeasurement"
            />
          </TabsContent>
          <TabsContent value="function" class="tab-content">
            <GroupedTab
              :type="TabType.Function"
              :groups="filteredGroupedMeasurements.functions"
              :opened-groups="openedFunctionGroups"
              v-bind="commonTabProps"
              @update:opened-groups="openedFunctionGroups = $event"
              @update:custom-typed-measurement-name="createCustomTypedMeasurement($event)"
              @update:is-measurement-card-expanded="updateMeasurementCardExpanded"
              @update:measurement-display-option="updateMeasurementDisplayOption"
              @take-measurement="takeMeasurement"
            />
          </TabsContent>
        </OverlayScrollbar>
      </Tabs>

      <SelectedClipMeasurements
        v-if="visibleClipMeasurements.length"
        v-bind="commonTabProps"
        :measurements="visibleClipMeasurements"
        :is-visible="isMeasurementsOnSelectedClipVisible"
        :selected-clip-ids="selectedClipIds"
        :is-measurement-card-expanded="isMeasurementCardExpanded"
        @update:opened-groups="openedFunctionGroups = $event"
        @update:custom-typed-measurement-name="createCustomTypedMeasurement($event)"
        @update:is-measurement-card-expanded="updateMeasurementCardExpanded"
        @update:measurement-display-option="updateMeasurementDisplayOption"
        @take-measurement="takeMeasurement"
        @toggle-visibility="
          isMeasurementsOnSelectedClipVisible = !isMeasurementsOnSelectedClipVisible
        "
      />

      <ActiveMeasurementCalculation
        v-if="activeMeasurementCalculation"
        :study="study"
        :calculation="activeMeasurementCalculation"
        :top-shadow="visibleClipMeasurements.length === 0 || isMeasurementsOnSelectedClipVisible"
        @jump-to-value="(value) => emits('jump-to-measurement-value', value)"
        @scroll-to-measurement="emits('scroll-to-measurement', $event)"
        @highlight-measurement-card="emits('highlight-measurement-card', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getMatchingMeasurements } from "@/measurements/measurement-search";
import { ReferenceRangeSetName } from "@/reference-ranges/reference-range-sets";
import { Study, StudyMeasurement, StudyMeasurementValue } from "@/utils/study-data";
import { useStorage } from "@vueuse/core";
import { computed, nextTick, Ref, ref, watch, watchEffect } from "vue";
import { isStudyUpdatePermitted } from "../../auth/authorization";
import { activeMeasurementCalculation } from "../calculations/measurement-calculations";

import FilterInput from "@/components/FilterInput.vue";
import IconButton from "@/components/IconButton.vue";
import OverlayScrollbar from "@/components/OverlayScrollbar.vue";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import RemarkCard from "@/measurements/RemarkCard.vue";
import ActiveMeasurementCalculation from "@/measurements/calculations/ActiveMeasurementCalculation.vue";
import { PatientMetrics } from "../../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { StudyMeasurementDisplayOption } from "../../../../backend/src/studies/study-measurement-enums";
import { RegularClipsGridItem } from "../../study-view/clip-viewer/clips-grid-item";
import { removeNullish } from "../../utils/array-helpers";
import { getEnabledMeasurementTools } from "../measurement-tool-helpers";
import { activeMeasurement, startMeasuring } from "../measurement-tool-state";
import AddCustomMeasurementButton from "./components/AddCustomMeasurementButton.vue";
import AlphabeticalTab from "./components/AlphabeticalTab.vue";
import GroupedTab from "./components/GroupedTab.vue";
import SelectedClipMeasurements from "./components/SelectedClipMeasurements.vue";
import {
  createSortedGroupArray,
  groupMeasurementsByFunctionAndStructure,
  sortMeasurementsAlphabetically,
} from "./utils/grouped-measurements";
import { TabType } from "./utils/tabs";

interface Props {
  study: Study;
  searchTerm: string;
  referenceRangeSetName: ReferenceRangeSetName;
  patientMetrics: PatientMetrics | undefined;
  selectedClipIds: string[];
  measurementIdToScrollTo: string | null;
  measurementIdsToExpand: string[] | undefined;
  clipsGridItems: RegularClipsGridItem[];
  highlightedMeasurementId: string | null;
  hoveredMeasurementValueId: string | null;
}

interface Emits {
  (event: "jump-to-measurement-value", measurementValue: StudyMeasurementValue): void;
  (event: "scroll-to-measurement", measurementId: string): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "update:searchTerm", newValue: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isAddCustomMeasurementCardVisible = ref(false);

const customTypedMeasurement = ref<StudyMeasurement | undefined>(undefined);

const expanded = useStorage("measurement-pane-expanded", true);

const measurements = computed(() => props.study.measurements.filter((m) => m.values.length > 0));

const studyUpdatePermitted = computed(() => isStudyUpdatePermitted(props.study));

const groupedMeasurements = computed(() => {
  const { functionGroups, structureGroups } = groupMeasurementsByFunctionAndStructure(
    measurements.value
  );

  const functions = createSortedGroupArray(functionGroups, TabType.Function);
  const structures = createSortedGroupArray(structureGroups, TabType.Structure);

  return { functions, structures };
});

const visibleFrames = computed(() =>
  props.clipsGridItems
    .filter((clipModel) => clipModel.clip !== undefined && !clipModel.isPlaying.value)
    .map((clipModel) => ({
      studyClipId: clipModel.clip!.id,
      frame: clipModel.getCurrentFrame(),
      soloMeasurementValueId: clipModel.soloMeasurementValueId.value,
    }))
);

// Todo: We should move this to a more global place

const visibleClips = computed(() => removeNullish(props.clipsGridItems.map((model) => model.clip)));

/**
 * Filters out measurement tools that are not available for the given clips.
 */
const enabledMeasurementTools = computed(() =>
  // Non CT set to true, as visible clips is guaranteed to be non-CT clips by the time it reaches this point
  Object.entries(getEnabledMeasurementTools(true, visibleClips.value))
    .filter(([_, enabled]) => enabled)
    .map(([toolName]) => toolName as MeasurementToolName)
);

const openDefaultStructuredItems = computed(() =>
  groupedMeasurements.value.structures.map((group) => group.name)
);

const openDefaultFunctionItems = computed(() =>
  groupedMeasurements.value.functions.map((group) => group.name)
);

// The last active tab is stored in localStorage. We use this to restore the last active tab when
// the user returns to the measurement pane, or enters the measurement pane from another study.
// This also ensures that this persists between tabs + sessions.
const storedTab = useStorage<string>("measurement-pane-active-tab", TabType.Alphabetical);

const activeTab = computed<TabType>({
  get: () => {
    return Object.values(TabType).includes(storedTab.value as TabType)
      ? (storedTab.value as TabType)
      : TabType.Alphabetical;
  },
  set: (value: TabType) => {
    storedTab.value = value;
  },
});

const openedStructureGroups = ref<string[]>([]);
const openedFunctionGroups = ref<string[]>([]);

const isAccordionsExpanded = computed(() => {
  if (activeTab.value === TabType.Alphabetical) {
    return true;
  }

  if (activeTab.value === TabType.Structure) {
    return openedStructureGroups.value.length > 0;
  }

  return openedFunctionGroups.value.length > 0;
});

// Initialize openStructuredItems with the default values
watch(
  openDefaultStructuredItems,
  (newValue) => {
    openedStructureGroups.value = newValue;
  },
  { immediate: true }
);

// Initialize openFunctionItems with the default values
watch(
  openDefaultFunctionItems,
  (newValue) => {
    openedFunctionGroups.value = newValue;
  },
  { immediate: true }
);

function createCustomTypedMeasurement(measurement: StudyMeasurement) {
  customTypedMeasurement.value = measurement;
  isAddCustomMeasurementCardVisible.value = true;
}

function collapseExpandAccordions() {
  const groupsRef =
    activeTab.value === TabType.Structure ? openedStructureGroups : openedFunctionGroups;

  const defaultItemsRef =
    activeTab.value === TabType.Structure ? openDefaultStructuredItems : openDefaultFunctionItems;

  if (groupsRef.value.length === 0) {
    groupsRef.value = defaultItemsRef.value;
  } else {
    groupsRef.value = [];
  }
}

const filteredAlphabeticalMeasurements = computed(() =>
  sortMeasurementsAlphabetically(
    getMatchingMeasurements(measurements.value, props.searchTerm, props.patientMetrics)
  )
);

const filteredGroupedMeasurements = computed(() => {
  const { functionGroups, structureGroups } = groupMeasurementsByFunctionAndStructure(
    measurements.value
  );

  // Filter by group name and measurements matching the search term
  function filterGroup(groupName: string, measurements: StudyMeasurement[]) {
    const groupMatchesSearch = groupName.toLowerCase().includes(props.searchTerm.toLowerCase());

    const filteredMeasurements = getMatchingMeasurements(
      measurements,
      props.searchTerm,
      props.patientMetrics
    );

    return groupMatchesSearch ? measurements : filteredMeasurements;
  }

  const functions = createSortedGroupArray(
    new Map(
      Array.from(functionGroups.entries()).map(([groupName, measurements]) => [
        groupName,
        filterGroup(groupName, measurements),
      ])
    ),
    TabType.Function
  );

  const structures = createSortedGroupArray(
    new Map(
      Array.from(structureGroups.entries()).map(([groupName, measurements]) => [
        groupName,
        filterGroup(groupName, measurements),
      ])
    ),
    TabType.Structure
  );

  return { functions, structures };
});

const isMeasurementsOnSelectedClipVisible = useStorage("measurement-pane-selected-clips", false);

const cardToScrollTo = ref<HTMLElement | null>(null);
const clipCardToScrollTo = ref<HTMLElement | null>(null);

const visibleClipMeasurements = computed((): StudyMeasurement[] => {
  return measurements.value
    .filter((measurement) =>
      measurement.values.some(
        (value) => value.studyClipId !== null && props.selectedClipIds.includes(value.studyClipId)
      )
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

watch(
  () => props.measurementIdToScrollTo,
  async () => {
    if (props.measurementIdToScrollTo === null) {
      cardToScrollTo.value = null;
      clipCardToScrollTo.value = null;
      return;
    }

    // Clear the search term here so that the measurement to scroll to is not filtered out
    emits("update:searchTerm", "");

    // Wait for the next tick to ensure new results are rendered prior to scrolling
    await nextTick();

    await scrollToMeasurement(props.measurementIdToScrollTo);
  }
);

async function scrollToMeasurement(measurementId: string) {
  // Expand the measurement card
  isMeasurementCardExpanded.value[measurementId] = true;

  // Wait for the next tick to ensure the card is expanded before scrolling
  await nextTick();

  switch (activeTab.value) {
    case TabType.Alphabetical:
      scrollToAlphabeticalMeasurement(measurementId);
      break;
    case TabType.Structure:
      await scrollToGroupedMeasurement(
        measurementId,
        groupedMeasurements.value.structures,
        openedStructureGroups
      );
      break;
    case TabType.Function:
      await scrollToGroupedMeasurement(
        measurementId,
        groupedMeasurements.value.functions,
        openedFunctionGroups
      );
      break;
  }
}

function takeMeasurement(value: { tool: MeasurementToolName; name: MeasurementName }) {
  startMeasuring({ tool: value.tool, study: props.study, clipId: "", informOtherWindows: true });

  activeMeasurement.value.measurementName.value = value.name;
  activeMeasurement.value.isMeasurementNameFixed.value = true;
}

function scrollToAlphabeticalMeasurement(measurementId: string) {
  const element = document.getElementById(`measurement-card-${measurementId}`);
  if (element) {
    scrollToElement(element);
  }
}

async function scrollToGroupedMeasurement(
  measurementId: string,
  groups: { name: string; measurements: StudyMeasurement[] }[],
  openedGroups: Ref<string[]>
) {
  const group = groups.find((g) => g.measurements.some((m) => m.id === measurementId));
  if (!group) return;

  if (!openedGroups.value.includes(group.name)) {
    openedGroups.value.push(group.name);
    await nextTick();
  }

  const element = document.getElementById(`measurement-card-${measurementId}`);
  if (element) {
    scrollToElement(element);
  }
}

const isMeasurementCardExpanded: Ref<Record<string, boolean>> = ref({});

// Ensure there are explicit values for each measurement in isMeasurementCardExpanded
watchEffect(() => {
  for (const measurement of measurements.value) {
    if (measurement.id in isMeasurementCardExpanded.value) {
      continue;
    }

    isMeasurementCardExpanded.value[measurement.id] = false;
  }
});

// When the set of measurement ids to expand changes, ensure that only those measurement cards are
// expanded
watch(
  () => props.measurementIdsToExpand,
  () => {
    if (props.measurementIdsToExpand === undefined) {
      return;
    }

    for (const measurement of measurements.value) {
      isMeasurementCardExpanded.value[measurement.id] = props.measurementIdsToExpand.includes(
        measurement.id
      );
    }

    // Close the 'measurements on selected clips' section when expanding a set of measurement cards
    isMeasurementsOnSelectedClipVisible.value = false;
  }
);

const commonTabProps = computed(() => ({
  study: props.study,
  highlightedMeasurementId: props.highlightedMeasurementId,
  hoveredMeasurementValueId: props.hoveredMeasurementValueId,
  referenceRangeSetName: props.referenceRangeSetName,
  patientMetrics: props.patientMetrics,
  visibleFrames: visibleFrames.value,
  isMeasurementCardExpanded: isMeasurementCardExpanded.value,
  searchTerm: props.searchTerm,
  enabledMeasurementTools: enabledMeasurementTools.value,
  studyUpdatePermitted: studyUpdatePermitted.value,

  // Here, we just pass the function as a prop as opposed to emitting all the way up the component stack.
  // This way only one emit is needed to jump to a measurement value.
  onJumpToValue: (value: StudyMeasurementValue) => emits("jump-to-measurement-value", value),
}));

function updateMeasurementCardExpanded(id: string, value: boolean) {
  isMeasurementCardExpanded.value[id] = value;
}

function updateMeasurementDisplayOption(id: string, value: StudyMeasurementDisplayOption) {
  const measurementToUpdate = props.study.measurements.find((m) => m.id === id);

  if (measurementToUpdate) {
    measurementToUpdate.displayOption = value;
  }
}

function scrollToElement(element: HTMLElement) {
  element.scrollIntoView({ behavior: "smooth", block: "nearest" });
}
</script>

<style scoped lang="scss">
.measurement-pane {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--bg-color-2);
}

:deep(.add-custom-measurement-card-popper) {
  padding: 0;
}

.toolbar {
  background-color: var(--bg-color-2);
  padding: 4px 4px 2px 6px;

  .toolbar-buttons {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.measurement-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; // Important for nested flexbox scrolling
  width: 300px;
}

.measurement-alerts {
  padding: 0 4px 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tabs-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// todo: 220px max for custom measurement

.measurement-cards {
  display: flex;
  flex-direction: column;
}

.measurements-divider {
  background-color: var(--bg-color-3);
  padding: 0 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  border-top: 1px solid var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);

  // Put a shadow on the top edge of the divider to better separate it visually from the
  // scrolling content above
  box-shadow: 0 0 8px 4px rgba(black, 0.5);
  clip-path: inset(-15px 0 0 0);
  z-index: 1;

  svg {
    color: var(--accent-color-1);
    transition: color 100ms ease;
  }

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-3);

    svg {
      color: var(--text-color-2);
    }
  }
}
</style>
