<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <slot />
    </ContextMenuTrigger>
    <ContextMenuPortal>
      <ContextMenuContent data-testid="measurement-card-context-menu">
        <template v-for="option in contextMenuOptions" :key="option.label">
          <ContextMenuItem
            v-if="!option.children"
            :data-testid="option.dataTestId"
            :disabled="option.disabled"
            @click="option.action"
          >
            {{ option.label }}
          </ContextMenuItem>
          <ContextMenuSub v-else>
            <ContextMenuSubTrigger :data-testid="option.dataTestId" :disabled="option.disabled">
              {{ option.label }}
              <div class="right-slot">
                <FontAwesomeIcon icon="chevron-right" />
              </div>
            </ContextMenuSubTrigger>
            <ContextMenuPortal>
              <ContextMenuSubContent>
                <ContextMenuItem
                  v-for="child in option.children"
                  :key="child.label"
                  :data-testid="child.dataTestId"
                  :disabled="child.disabled"
                  @click="child.action"
                >
                  {{ child.label }}
                </ContextMenuItem>
              </ContextMenuSubContent>
            </ContextMenuPortal>
          </ContextMenuSub>
        </template>
      </ContextMenuContent>
    </ContextMenuPortal>
  </ContextMenu>
</template>

<script setup lang="ts">
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuOption,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { StudyMeasurementDisplayOption } from "../../../backend/src/studies/study-measurement-enums";

interface Props {
  isIndexable?: boolean;
  displayOption: StudyMeasurementDisplayOption;
  someValuesOn: boolean;
  canTakeMeasurement: boolean;
  customMeasurementsEnabled: boolean;
  studyUpdatePermitted: boolean;
}

interface Emits {
  (event: "take-measurement"): void;
  (event: "toggle-all-selected"): void;
  (event: "update:custom-typed-measurement-name"): void;
  (event: "update:display-option", value: StudyMeasurementDisplayOption): void;
}

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const contextMenuOptions = computed<ContextMenuOption[]>(() => {
  const baseOptions: ContextMenuOption[] = [
    ...(props.canTakeMeasurement
      ? [
          {
            label: "Take this measurement",
            dataTestId: "take-measurement-option",
            action: () => emits("take-measurement"),
          },
        ]
      : []),
    ...(props.customMeasurementsEnabled
      ? [
          {
            label: "Type custom measurement",
            dataTestId: "type-custom-measurement-option",
            action: () => emits("update:custom-typed-measurement-name"),
          },
        ]
      : []),
    {
      label: `Toggle all values ${props.someValuesOn ? "OFF" : "ON"}`,
      dataTestId: "toggle-all-values-option",
      disabled: !props.studyUpdatePermitted,
      action: () => emits("toggle-all-selected"),
    },
  ];

  if (!props.isIndexable) {
    return baseOptions;
  }

  const displayOptions: ContextMenuOption = {
    label: getDisplayOptionLabel(props.displayOption),
    dataTestId: "display-option-menu",
    disabled: !props.studyUpdatePermitted,
    action: () => null,
    children: Object.values(StudyMeasurementDisplayOption).map((option) => ({
      label: getDisplayOptionLabel(option),
      dataTestId: `context-menu-display-option-${option}`,
      action: () => emits("update:display-option", option),
    })),
  };

  return [displayOptions, ...baseOptions];
});

function getDisplayOptionLabel(displayOption: StudyMeasurementDisplayOption): string {
  switch (displayOption) {
    case StudyMeasurementDisplayOption.PreferIndexed:
      return "Display Indexed";
    case StudyMeasurementDisplayOption.Unindexed:
      return "Display Unindexed";
    case StudyMeasurementDisplayOption.UnindexedAndIndexed:
      return "Display Unindexed and Indexed";
    default:
      return "";
  }
}
</script>

<style scoped>
.right-slot {
  margin-left: auto;
  padding-left: 20px;
  color: white;
}
</style>
