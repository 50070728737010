import type { Study } from "@/utils/study-data";
import axios, { type AxiosResponse } from "axios";
import { isEqual } from "lodash";
import { evaluateCalculatedMeasurements } from "../../../backend/src/measurements/measurement-calculation-evaluation";
import { mathjsInstance } from "../../../backend/src/shared/mathjs";
import type { StudyMeasurementValueResponseDto } from "../../../backend/src/studies/dto/study-get-one.dto";
import type {
  StudyMeasurementBatchChangeRequestDto,
  StudyMeasurementBatchChangeResponseDto,
} from "../../../backend/src/studies/dto/study-measurement-batch-change.dto";
import { getClips } from "../../../backend/src/studies/study-helpers";
import { postMessageToPrimaryWindow } from "../study-view/multi-window/primary-window-messages";
import { WindowType, getWindowType } from "../study-view/multi-window/secondary-window";
import { addNotification } from "../utils/notifications";
import {
  activeMeasurementSequence,
  advanceMeasurementSequence,
  stopMeasuring,
} from "./measurement-tool-state";

/**
 * Performs a batch change of measurements on the passed study. This can involve creating, updating
 * or deleting measurements as required by associated measurements being enabled/disabled/changed
 * as needed in the measurement tool.
 */
export async function changeBatchMeasurements(
  study: Study,
  request: StudyMeasurementBatchChangeRequestDto
): Promise<StudyMeasurementBatchChangeResponseDto | undefined> {
  if (
    (request.creates?.length ?? 0) === 0 &&
    (request.updates?.length ?? 0) === 0 &&
    (request.deletes?.length ?? 0) === 0
  ) {
    return;
  }

  // If this is called from a secondary window then it will send the request to the primary window.
  if (getWindowType() === WindowType.ExtraClips) {
    postMessageToPrimaryWindow({
      type: "change-measurements",
      measurementsJson: JSON.stringify(request),
    });

    if (activeMeasurementSequence.value !== null) {
      advanceMeasurementSequence(study);
    } else {
      stopMeasuring();
    }

    return undefined;
  }

  let response: AxiosResponse<StudyMeasurementBatchChangeResponseDto> | undefined = undefined;
  for (const requestData of request.creates ?? []) {
    // For measurements on CT/MR clips, replace the `studyClipId` and `frame` with the actual study
    // clip ID for the frame being displayed. The frame is always zero because CTs and MRs are stored
    // as one slice per DICOM.

    const clip = getClips(study).find((c) => c.id === requestData.studyClipId);
    if (clip && clip.frameStudyClipDetails !== undefined && requestData.frame !== undefined) {
      requestData.studyClipId = clip.frameStudyClipDetails[requestData.frame].studyClipId;
      requestData.frame = 0;
    }
  }

  try {
    response = await axios.patch<StudyMeasurementBatchChangeResponseDto>(
      `/api/studies/${study.id}/measurements`,
      request
    );
  } catch {
    addNotification({ type: "error", message: "Measurement save failed" });
    return undefined;
  }

  const results = response.data;

  handleCreateResponses(study, request, results);
  handleUpdateResponses(study, results);
  handleDeleteResponses(study, results);

  evaluateCalculatedMeasurements(mathjsInstance, study);

  if (
    (request.creates?.length ?? 0) === 0 &&
    (request.updates?.length ?? 0) === 0 &&
    (request.deletes?.length ?? 0) !== 0
  ) {
    addNotification({
      type: "info",
      message: `Measurement${(request.deletes?.length ?? 0) > 1 ? "s" : ""} deleted`,
    });
  } else {
    addNotification({ type: "info", message: "Measurement saved" });
  }

  return results;
}

function handleCreateResponses(
  study: Study,
  request: StudyMeasurementBatchChangeRequestDto,
  results: StudyMeasurementBatchChangeResponseDto
): void {
  const studyClipIds = request.creates?.map((r) => r.studyClipId ?? null) ?? [];
  const frames = request.creates?.map((r) => r.frame ?? null) ?? [];

  for (const [idx, result] of results.createResponses.entries()) {
    // Put new measurement on if it's not present
    if (!study.measurements.find((m) => m.id === result.measurement.id)) {
      study.measurements.push({
        id: result.measurement.id,
        studyId: study.id,
        name: result.measurement.name,
        customName: result.measurement.customName,
        customUnit: result.measurement.customUnit,
        values: [],
        displayOption: result.measurement.displayOption,
      });
    }

    // Push new value on
    if (result.measurementValue) {
      const measurement = study.measurements.find((m) => m.id === result.measurement.id);
      if (measurement === undefined) {
        throw Error("Expected measurement not present immediately after creation");
      }

      const fakedClipForMeasurement = getClips(study).find((c) =>
        c.frameStudyClipDetails?.map((s) => s.studyClipId).includes(studyClipIds[idx] ?? "")
      );

      // If the measurement was created on a fake study clip, we need to link the created
      // measurement back to the faked frame & study clip ID
      const studyClipIdAndFrame =
        fakedClipForMeasurement !== undefined
          ? {
              studyClipId: fakedClipForMeasurement.id,
              frame:
                fakedClipForMeasurement.frameStudyClipDetails?.findIndex(
                  (c) => c.studyClipId === studyClipIds[idx]
                ) ?? 0,
            }
          : {
              studyClipId: studyClipIds[idx],
              frame: frames[idx],
            };

      const newMeasurementValue = {
        ...result.measurementValue,
        ...studyClipIdAndFrame,
      };

      // Don't add exact copies of pre-existing measurements
      if (!measurement.values.some((v) => isEqual(newMeasurementValue, v))) {
        measurement.values.push(newMeasurementValue);
      }
    }
  }
}

function handleUpdateResponses(
  study: Study,
  results: StudyMeasurementBatchChangeResponseDto
): void {
  for (const result of results.updateResponses) {
    const studyMeasurement = study.measurements.find((m) => m.id === result.measurement.id);
    const studyMeasurementValueIndex =
      studyMeasurement?.values.findIndex((v) => v.id === result.measurementValue.id) ?? -1;

    if (studyMeasurement !== undefined && studyMeasurementValueIndex !== -1) {
      studyMeasurement.values[studyMeasurementValueIndex] = result.measurementValue;

      if (result.measurement.customName !== "") {
        studyMeasurement.customName = result.measurement.customName;
      }
    } else {
      // Remove the measurement value from the old measurement if needed.
      // This occurs when the measurement name is changed in editing.
      for (const m of study.measurements) {
        for (let i = 0; i < m.values.length; i++) {
          if (m.values[i].id === result.measurementValue.id) {
            m.values.splice(i, 1);
            break;
          }
        }
      }

      if (studyMeasurement !== undefined) {
        studyMeasurement.values.push(result.measurementValue);
      } else {
        study.measurements.push({
          id: result.measurement.id,
          studyId: study.id,
          name: result.measurement.name,
          customName: result.measurement.customName,
          customUnit: result.measurement.customUnit,
          values: [result.measurementValue],
          displayOption: result.measurement.displayOption,
        });
      }
    }
  }
}

function handleDeleteResponses(
  study: Study,
  results: StudyMeasurementBatchChangeResponseDto
): void {
  for (const result of results.deleteResponses) {
    const studyMeasurement = study.measurements.find((m) => m.id === result.measurementId);
    const studyMeasurementValueIndex =
      studyMeasurement?.values.findIndex((v) => v.id === result.measurementValueId) ?? -1;

    if (studyMeasurementValueIndex !== -1) {
      studyMeasurement?.values.splice(studyMeasurementValueIndex, 1);
    }
  }

  // Remove any measurements that no longer have any values
  study.measurements = study.measurements.filter((m) => m.values.length > 0);
}

/**
 * Updates the selected state of a single measurement value.
 */
export async function updateMeasurementValueSelected(
  study: Study,
  measurementValue: StudyMeasurementValueResponseDto,
  newValue: boolean
): Promise<void> {
  try {
    await axios.patch(
      `/api/studies/${study.id}/measurements/${measurementValue.measurementId}/values/update-selected`,
      { selectedValues: [{ measurementValueId: measurementValue.id, selected: newValue }] }
    );
  } catch {
    addNotification({ type: "error", message: "Failed saving measurement" });
    return;
  }

  measurementValue.selected = newValue;

  evaluateCalculatedMeasurements(mathjsInstance, study);
}

export async function updateMeasurementValuesSelected(
  study: Study,
  measurementId: string,
  newMeasurementSelectedValues: {
    measurementValue: StudyMeasurementValueResponseDto;
    selected: boolean;
  }[]
): Promise<void> {
  const requestBody = newMeasurementSelectedValues.map(({ measurementValue, selected }) => ({
    measurementValueId: measurementValue.id,
    selected,
  }));

  try {
    await axios.patch(
      `/api/studies/${study.id}/measurements/${measurementId}/values/update-selected`,
      { selectedValues: requestBody }
    );
  } catch {
    addNotification({ type: "error", message: "Failed saving measurements" });
    return;
  }

  for (const { measurementValue, selected } of newMeasurementSelectedValues) {
    measurementValue.selected = selected;
  }

  evaluateCalculatedMeasurements(mathjsInstance, study);
}
