<template>
  <div class="signature-panel">
    <canvas ref="canvas" data-testid="signature-canvas" />

    <div class="signature-panel-buttons">
      <input
        ref="fileInput"
        data-testid="upload-image-input"
        type="file"
        accept="image/*"
        style="display: none"
        @change="handleSignatureImageUpload"
      />
      <IconButton
        icon="image"
        highlight
        size="lg"
        :type="'solid-square'"
        @click="triggerFileUpload"
      >
        Upload Image
      </IconButton>

      <IconButton
        data-testid="clear-signature"
        icon="rotate-left"
        highlight
        tooltip="Clear Signature"
        size="lg"
        :type="'solid-square'"
        :disabled="isEmpty"
        @click="clearSignature"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import SignaturePad from "signature_pad";
import { onMounted, onUnmounted, ref } from "vue";
import { currentUser } from "../auth/current-session";
import { addNotification } from "../utils/notifications";
import IconButton from "./IconButton.vue";

interface Props {
  onboarding?: boolean;
}

const props = withDefaults(defineProps<Props>(), { onboarding: false });

const canvas = ref<HTMLCanvasElement>();
const fileInput = ref<HTMLInputElement>();

let signaturePad: SignaturePad | undefined = undefined;

const isEmpty = ref(true);

onMounted(async () => {
  signaturePad = new SignaturePad(canvas.value!);
  signaturePad.on();

  // Save signature when a stroke ends
  signaturePad.addEventListener("endStroke", updateUserSignature);

  // Set initial state of signature pad
  if (currentUser.signatureDataUri !== "") {
    await signaturePad.fromDataURL(currentUser.signatureDataUri, {
      width: canvas.value!.width,
      height: canvas.value!.height,
    });

    isEmpty.value = signaturePad.isEmpty();
  }
});

onUnmounted(() => {
  signaturePad?.off();
  signaturePad = undefined;
});

function triggerFileUpload() {
  fileInput.value?.click();
}

function handleSignatureImageUpload(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = async () => {
        signaturePad?.clear();
        await drawImageOnCanvas(img);
        updateUserSignature();
      };

      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
  }
}

async function drawImageOnCanvas(img: HTMLImageElement) {
  if (!canvas.value) return;

  const ctx = canvas.value.getContext("2d");
  if (!ctx) return;

  // Calculate aspect ratio
  const scale = Math.min(canvas.value.width / img.width, canvas.value.height / img.height);
  const x = (canvas.value.width - img.width * scale) / 2;
  const y = (canvas.value.height - img.height * scale) / 2;

  // Draw the image to the canvas
  ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

  // Update SignaturePad with the new image
  await signaturePad?.fromDataURL(canvas.value.toDataURL(), {
    width: canvas.value.width,
    height: canvas.value.height,
  });
}

function updateUserSignature(): void {
  const signaturePadEmpty = signaturePad?.isEmpty();
  const newSignatureDataURI = signaturePadEmpty ?? false ? "" : signaturePad?.toDataURL() ?? "";

  currentUser.signatureDataUri = newSignatureDataURI;
  isEmpty.value = signaturePadEmpty ?? true;

  if (!props.onboarding) {
    void saveUserSignatureDebounced();
  }
}

function clearSignature(): void {
  signaturePad?.clear();
  updateUserSignature();
}

async function saveUserSignature(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { signatureDataUri: currentUser.signatureDataUri });
    addNotification({ type: "info", message: "Saved signature" });
  } catch {
    addNotification({ type: "error", message: "Failed saving signature" });
  }
}

const saveUserSignatureDebounced = useDebounceFn(() => {
  void saveUserSignature();
}, 1000);
</script>

<style scoped lang="scss">
.signature-panel {
  display: grid;
  gap: 8px;
  width: 320px;
}

canvas {
  background: #f8f8f8;
  width: 320px;
  height: 140px;
  border-radius: var(--border-radius);
}

.clear-signature {
  justify-self: end;
}

.signature-panel-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
