import { getLatestReport } from "@/utils/study-data";
import { computed } from "vue";
import {
  GlobalAdminPermission,
  Permission,
} from "../../../backend/src/auth/permissions/permissions";
import { RoleType } from "../../../backend/src/user/roles/user-role-type";
import type { Study } from "../utils/study-data";
import { currentTenant, currentUser } from "./current-session";

export const currentUserRoles = computed(() =>
  currentTenant.roles.filter((r) => currentUser.roleIds.includes(r.id))
);

export const isGuest = computed(() =>
  currentUserRoles.value.some((r) => r.type === RoleType.SystemControlledGuest)
);

const currentUserGlobalAdminPermissions = computed(() => {
  if (!currentTenant.isGlobalAdmin) {
    return [];
  }

  return currentUser.globalAdminRolePermissions ?? [];
});

const currentUserPermissions = computed(() => {
  const permissions = new Set<string>();

  for (const role of currentUserRoles.value) {
    for (const permission of role.permissions) {
      permissions.add(permission);
    }
  }

  return permissions;
});

export const hasApiKeyIntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.ApiKeyIntegrationManage)
);
export const hasAgentIntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.AgentIntegrationManage)
);
export const hasAuditLogViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.AuditLogView)
);
export const hasBillingManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.BillingManage)
);
export const hasChartingViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.ChartingView)
);
export const hasCurrentUserUpdatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.CurrentUserUpdate)
);
export const hasDicomClientIdentityManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.DicomClientIdentityManage)
);
export const hasDicomEndpointQueryPermission = computed(() =>
  currentUserPermissions.value.has(Permission.DicomEndpointQuery)
);
export const hasDicomEndpointSendPermission = computed(() =>
  currentUserPermissions.value.has(Permission.DicomEndpointSend)
);
export const hasDicomScheduledProcedureStepManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.DicomScheduledProcedureStepManage)
);
export const hasHeartBoxIntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.HeartBoxIntegrationManage)
);
export const hasHL7IntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.HL7IntegrationManage)
);
export const hasHL7MessageDownloadPermission = computed(() =>
  currentUserPermissions.value.has(Permission.HL7MessageDownload)
);
export const hasHL7MessageSendPermission = computed(() =>
  currentUserPermissions.value.has(Permission.HL7MessageSend)
);
export const hasMeasurementCalculationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.MeasurementCalculationManage)
);
export const hasMeasurementSequenceManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.MeasurementSequenceManage)
);
export const hasReportTemplateManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.ReportTemplateManage)
);
export const hasSftpIntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.SftpIntegrationManage)
);
export const hasStudyDeletePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyDelete)
);
export const hasStudyClipDeletePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyClipDelete)
);
export const hasStudyListViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyListView)
);
export const hasStudyListExportPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyListExport)
);
export const hasStudyReportPreliminaryCreatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportPreliminaryCreate)
);
export const hasStudyReportPreliminaryCreateInitialPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportPreliminaryCreateInitial)
);
export const hasStudyReportPreliminaryCompletePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportPreliminaryComplete)
);
export const hasStudyReportPreliminaryFinalizePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportPreliminaryFinalize)
);
export const hasStudyReportAmendmentCreatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportAmendmentCreate)
);
export const hasStudyReportAmendmentCompletePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportAmendmentComplete)
);
export const hasStudyReportAmendmentCompleteWithUpdatedContentPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportAmendmentCompleteWithUpdatedContent)
);
export const hasStudyReportDeleteUnsignedPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportDeleteUnsigned)
);
export const hasStudyReportDeleteSignedPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReportDeleteSigned)
);
export const hasStudyReprocessPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyReprocess)
);
export const hasStudySharePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyShare)
);
export const hasStudyUpdatePatientIdentityPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyPatientIdentityUpdate)
);
export const hasStudyUpdatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyUpdate)
);
export const hasStudyUploadPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyUpload)
);
export const hasStudyViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyView)
);
export const hasStudyWebhookActivatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.StudyWebhookActivate)
);
export const hasTenantManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.TenantManage)
);
export const hasUserInvitePermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserInvite)
);
export const hasUserRolesUpdatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserRolesUpdate)
);
export const hasUserRoleManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserRoleManage)
);
export const hasUserRoleViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserRoleView)
);
export const hasUserUpdatePermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserUpdate)
);
export const hasUserViewPermission = computed(() =>
  currentUserPermissions.value.has(Permission.UserView)
);
export const hasWebhookIntegrationManagePermission = computed(() =>
  currentUserPermissions.value.has(Permission.WebhookIntegrationManage)
);

export const hasAdminTenantViewPermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.TenantView)
);
export const hasAdminTenantCreatePermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.TenantCreate)
);
export const hasAdminTenantUpdatePermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.TenantUpdate)
);
export const hasAdminTenantLoginPermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.TenantAdminLogin)
);
export const hasAdminBillingViewPermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.BillingView)
);
export const hasAdminBillingUpdatePermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.BillingUpdate)
);
export const hasAdminDicomEndpointManagePermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.DicomEndpointManage)
);
export const hasAdminRoleManagePermission = computed(() =>
  currentUserGlobalAdminPermissions.value.includes(GlobalAdminPermission.RoleManage)
);
export const hasAnyAdminPermission = computed(
  () => currentUserGlobalAdminPermissions.value.length > 0
);

export function isStudyUpdatePermitted(study: Study): boolean {
  // When in the report template editor, always allow updates to the fake study that's
  // put in there
  if (study.id === "report-template-editor") {
    return true;
  }

  if (!hasStudyUpdatePermission.value) {
    return false;
  }

  return getLatestReport(study.reports)?.isSigned !== true;
}
