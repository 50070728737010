import type { Study, StudyClip, StudyClipRegion } from "@/utils/study-data";
import Flatten from "@flatten-js/core";
import { computed, reactive, ref, watch } from "vue";
import { getDrawableAngleMeasurements } from "../../../../backend/src/measurements/drawable-measurements";
import { MeasuredValue } from "../../../../backend/src/measurements/measured-value";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import { getAngleMeasurementValue } from "../../../../backend/src/measurements/measurement-tool-evaluation";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { buildMeasurementToolBatchChangeRequest } from "../measurement-tool-helpers";
import { drawContourEdges } from "./measurement-base-contour";
import type {
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "./measurement-tool";
import { getAreaMeasurementLabelPosition } from "./measurement-tool-area";
import {
  RESTART_MEASUREMENT_ICON,
  checkForMeasurementPointEdits,
} from "./measurement-tool-helpers";
import { measurementIncompleteTooltipText } from "./measurement-tooltips";

/** Creates a new angle measurement tool instance for the given study. */
export function createAngleMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomAngle;
  const customName = ref("");

  const frame = ref(0);

  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const anglePoints = reactive<number[]>([]);

  const lastMousePosition = ref<number[] | null>(null);

  let editingPointIndex: number | null = null;
  let editPointOffset = [0, 0];

  const isComplete = computed(() => anglePoints.length === 6);

  const measuredValue = computed(() => {
    const points =
      anglePoints.length === 6 ? anglePoints : anglePoints.concat(lastMousePosition.value ?? []);

    if (
      !studyClip ||
      studyClip.width === null ||
      studyClip.height === null ||
      points.length !== 6
    ) {
      return MeasuredValue.Null;
    }

    return getAngleMeasurementValue(points, studyClip.width, studyClip.height);
  });

  const isSaveButtonEnabled = computed(() => saveButtonTooltip.value === "");

  const saveButtonTooltip = computed(() => {
    if (!isComplete.value) {
      return measurementIncompleteTooltipText;
    }

    return "";
  });

  const helpText = computed(() => (!isComplete.value ? "Click to place angle points" : ""));

  const toolbarItems = computed((): ToolbarItem[] => {
    const items: ToolbarItem[] = [
      {
        text: measuredValue.value.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: () => {
          anglePoints.splice(0, anglePoints.length);
          lastMousePosition.value = null;
          editingPointIndex = null;
          editPointOffset = [0, 0];
        },
      });
    }

    return items;
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (!isComplete.value) {
      return [];
    }

    const position = getAreaMeasurementLabelPosition({
      canvas,
      points: anglePoints,
    });

    return [
      {
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: measuredValue.value.createTransientValue(
          measurementName.value ?? customMeasurementName,
          anglePoints,
          frame.value
        ),
        x: position.x,
        y: position.y,
      },
    ];
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    const points =
      anglePoints.length === 6 ? anglePoints : anglePoints.concat(lastMousePosition.value ?? []);

    drawContourEdges({
      canvas,
      ctx,
      points,
      isInvalid: false,
      drawMidpoints: true,
      pointOpacity: 0,
      opacity: 1,
    });

    drawAngleArcAndVertices({ canvas, ctx, points });
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (isComplete.value) {
      const edits = checkForMeasurementPointEdits(anglePoints, pt);
      if (edits !== undefined) {
        editingPointIndex = edits.editingPointIndex;
        editPointOffset = edits.editPointOffset;
      }

      return;
    }

    anglePoints.push(pt[0], pt[1]);
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    lastMousePosition.value = pt;

    if (editingPointIndex !== null) {
      anglePoints[editingPointIndex * 2] = pt[0] + editPointOffset[0];
      anglePoints[editingPointIndex * 2 + 1] = pt[1] + editPointOffset[1];
      return true;
    }

    return false;
  }

  function onCanvasMouseUp(_pt: number[]): void {
    editingPointIndex = null;
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const measuredValueUnitAndValue = measuredValue.value.getInternalValueAndUnit() ?? null;
    if (measurementName.value === undefined || measuredValueUnitAndValue === null) {
      return {};
    }

    const measurementDetails = {
      ...measuredValueUnitAndValue,
      name: measurementName.value,
      tool: MeasurementToolName.Angle,
      customName: isCustomMeasurement(measurementName.value) ? customName.value : "",
      frame: frame.value,
      contour: anglePoints,
      studyClipId,
    };

    return buildMeasurementToolBatchChangeRequest(
      measurementDetails,
      savedMeasurementBeingEdited.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    anglePoints.splice(0, anglePoints.length, ...value.contour);
    frame.value = value.frame ?? 0;
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([anglePoints, lastMousePosition], () => requestRedrawHandlers.forEach((fn) => fn()));

  return {
    displayName: "Angle Measurement",
    studyClipId,
    region,
    measurementName,
    customName,
    customMeasurementName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled,
    saveButtonTooltip,
    helpText,
    toolbarItems,
    shouldPassMousedownAfterRegionSelection: true,

    interactivePoints: computed(() => anglePoints),
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),

    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (frameNumber: number) => (frame.value = frameNumber),
    isChangeAllowedOf: (_target: "clip" | "frame") => anglePoints.length === 0,

    associatedMeasurements: computed(() => ({})),
    toggleAssociatedMeasurement: () => null,
    getCreatableMeasurementNames: getDrawableAngleMeasurements,
    getMeasurementChangeRequests,
    isMeasurableOnStudyClip: () => true,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function drawAngleArcAndVertices({
  canvas,
  ctx,
  points,
}: {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  points: number[];
}): void {
  // Draw vertices
  for (let i = 0; i < points.length; i += 2) {
    ctx.beginPath();
    ctx.arc(points[i] * canvas.width, points[i + 1] * canvas.height, 2, 0, 2 * Math.PI);
    ctx.fill();
  }

  // Draw the angle arc
  if (points.length < 6) {
    return;
  }

  try {
    const vec1 = Flatten.vector(
      Flatten.point(points[0], points[1]),
      Flatten.point(points[2], points[3])
    );

    const vec2 = Flatten.vector(
      Flatten.point(points[4], points[5]),
      Flatten.point(points[2], points[3])
    );

    // The angle between the vectors before we clamp >180 degree angles to 0 - 180 degrees
    const measuredAngleBeforeClamp = vec1.angleTo(vec2) * (180 / Math.PI);

    const [x1, y1, x2, y2, x3, y3] = points.map((p, i) =>
      i % 2 === 0 ? p * canvas.width : p * canvas.height
    );

    const radius1 = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    const radius2 = Math.sqrt((x3 - x2) ** 2 + (y3 - y2) ** 2);
    const radius = Math.min(radius1, radius2) * 0.2;

    const angle1 = Math.atan2(y1 - y2, x1 - x2);
    const angle2 = Math.atan2(y3 - y2, x3 - x2);

    const startAngle = measuredAngleBeforeClamp < 180 ? angle1 : angle2;
    const endAngle = measuredAngleBeforeClamp < 180 ? angle2 : angle1;

    ctx.beginPath();
    ctx.arc(x2, y2, radius, startAngle, endAngle);
    ctx.stroke();
  } catch {
    return;
  }
}

export function isAngleMeasurableOnStudyClip(clip: StudyClip): boolean {
  return clip.regions.length > 0;
}
