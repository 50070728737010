<template>
  <div class="settings-title">Billing Management</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />
  </div>

  <div v-if="!isLoading" class="tenants-table">
    <div class="header">
      <div class="header-item">
        <div class="title">Name</div>
      </div>
      <div class="header-item">
        <div class="title">Allocated Usage</div>
      </div>

      <div class="header-item">
        <div class="title">% Used</div>
      </div>

      <div class="header-item">
        <div class="title">Storage Used</div>
      </div>

      <div class="header-item">
        <div class="title">Days Until Period End</div>
      </div>

      <div class="header-item">
        <div class="title"># of Non Billable Studies</div>
      </div>
    </div>

    <div class="header-line" />

    <div
      v-for="tenant in tenantRowDetails"
      :key="tenant.id"
      class="grid-table-row"
      @click="onViewTenant(tenant.id)"
    >
      <div :class="`highlight-${tenant.highlightColour}`">
        <Tooltip>
          <template #content>
            <div style="display: flex; flex-direction: column">
              <strong>Projected Usage at End of Cycle:</strong>
              <span>Echo: {{ formatPercentage(tenant.projectedUsage.echo) }}</span>
              <span>Vascular: {{ formatPercentage(tenant.projectedUsage.vascular) }}</span>
            </div>
          </template>
          {{ tenant.name }}
        </Tooltip>
      </div>

      <div>
        <strong>Echo:</strong> {{ tenant.echoQuota }}<br />
        <strong>Vascular:</strong>
        {{ tenant.vascularQuota }}
      </div>

      <div>
        <strong>Echo:</strong> {{ formatPercentage(tenant.echoPercentageUsed) }}<br />
        <strong>Vascular:</strong>
        {{ formatPercentage(tenant.vascularPercentageUsed) }}
      </div>

      <div>{{ formatPercentage(tenant.storagePercentageUsed) }}</div>

      <div>
        {{ tenant.daysUntilPeriodEnd > 0 ? tenant.daysUntilPeriodEnd : "N/A" }}
      </div>

      <div>
        {{ tenant.nonBillableStudyCount }}
      </div>
    </div>
  </div>

  <LoadingIndicator v-else style="align-self: center" size="2x" />
</template>

<script setup lang="ts">
import FilterInput from "@/components/FilterInput.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { addNotification } from "@/utils/notifications";
import { useQuery } from "@tanstack/vue-query";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, ref, watch } from "vue";
import type { BillingTenantGetManyResponseDto } from "../../../backend/src/billing/dto/billing-tenant-get-many.dto";
import Tooltip from "../components/Tooltip.vue";
import router from "../router";

const searchTerm = ref("");

const { isLoading, isError, data } = useQuery({
  queryKey: ["tenant-billing-details"],
  queryFn: async () => {
    const response = await axios.get<BillingTenantGetManyResponseDto>("/api/billings");

    return response.data;
  },
});

const addErrorNotification = useDebounceFn(() => {
  return addNotification({
    type: "error",
    message: "Failed to load tenants",
  });
}, 4000);

watch(isError, (newValue) => {
  if (newValue) {
    void addErrorNotification();
  }
});

const tenants = computed(
  () =>
    data.value?.filter((tenant) =>
      tenant.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    ) ?? []
);

async function onViewTenant(tenantId: string): Promise<void> {
  await router.push({ name: "settings-billing-tenant", params: { id: tenantId } });
}

function formatPercentage(percentage: number | null): string {
  return percentage !== null ? `${(percentage * 100).toFixed(2)}%` : "N/A";
}

function estimatePercentageQuotaUsedAtEndOfPeriod(
  currentUsagePercent: number,
  daysUntilPeriodEnd: number,
  periodLengthInMonths: number
): number {
  const usageInOneDay = currentUsagePercent / (periodLengthInMonths * 30.44 - daysUntilPeriodEnd);
  return currentUsagePercent + usageInOneDay * daysUntilPeriodEnd;
}

function getTenantRowDetails(tenant: BillingTenantGetManyResponseDto[0]) {
  const echoPercentageUsed =
    tenant.quotas.echo !== 0 ? tenant.usage.echo / tenant.quotas.echo : null;

  const vascularPercentageUsed =
    tenant.quotas.vascular !== 0 ? tenant.usage.vascular / tenant.quotas.vascular : null;

  const intStorageQuota = parseInt(tenant.quotas.storage);
  const intStorageUsage = parseInt(tenant.usage.storage);

  const storagePercentageUsed = intStorageQuota !== 0 ? intStorageUsage / intStorageQuota : null;

  const daysUntilPeriodEnd = Math.floor(
    (new Date(tenant.currentBillingPeriod?.end ?? 0).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const projectedUsage = {
    echo: estimatePercentageQuotaUsedAtEndOfPeriod(echoPercentageUsed ?? 0, daysUntilPeriodEnd, 12),
    vascular: estimatePercentageQuotaUsedAtEndOfPeriod(
      vascularPercentageUsed ?? 0,
      daysUntilPeriodEnd,
      12
    ),
  };

  const maxProjectedUsage = Math.max(
    projectedUsage.echo,
    projectedUsage.vascular,
    storagePercentageUsed ?? 0
  );

  const highlightColour = maxProjectedUsage > 1 ? "red" : maxProjectedUsage > 0.8 ? "orange" : "";

  return {
    id: tenant.id,
    name: tenant.name,
    echoQuota: tenant.quotas.echo,
    vascularQuota: tenant.quotas.vascular,

    echoPercentageUsed,
    vascularPercentageUsed,
    storagePercentageUsed,

    nonBillableStudyCount: tenant.usage.nonBillable,
    daysUntilPeriodEnd,

    highlightColour,
    projectedUsage,
  };
}

const tenantRowDetails = computed(() => {
  return tenants.value.map((tenant) => getTenantRowDetails(tenant));
});
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.tenants-table {
  display: grid;
  grid-template-columns: max-content 1fr 1fr 1fr 1fr 1fr;
  row-gap: 8px;

  .header {
    display: contents;

    .header-item {
      display: flex;
      gap: 12px;
      cursor: pointer;
      transition: color 100ms ease;

      .title {
        font-weight: bold;
      }

      .sort-icon {
        color: var(--accent-color-1);
        transition: color 100ms ease;
        visibility: hidden;

        &.visible {
          visibility: visible;
        }
      }

      &:hover {
        color: var(--text-color-2);

        .sort-icon {
          color: var(--accent-color-2);
        }
      }
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 6;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.highlight-red {
  border-left: 4px solid red;
}

.highlight-orange {
  border-left: 4px solid orange;
}
</style>
