<template>
  <ContextMenuSub v-bind="forwardedProps" :class="props.class">
    <slot />
  </ContextMenuSub>
</template>
<script setup lang="ts">
import { ContextMenuSub, useForwardProps, type ContextMenuSubProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuSubProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>
