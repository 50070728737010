<template>
  <ContextMenuItem
    :class="['context-menu-item', { disabled: props.disabled }, props.class]"
    v-bind="forwardedProps"
  >
    <slot />
  </ContextMenuItem>
</template>

<script setup lang="ts">
import { ContextMenuItem, type ContextMenuItemProps, useForwardProps } from "radix-vue";
import { HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<
  ContextMenuItemProps & {
    class?: HTMLAttributes["class"];
    disabled?: boolean;
  }
>();

const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>

<style scoped lang="scss">
.context-menu-item {
  cursor: pointer;
  min-width: 220px;
  color: white;
  background-color: var(--bg-color-6);
  border-radius: 4px;
  overflow: hidden;
  padding: 8px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);

  &:focus-visible {
    outline: none;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.context-menu-item[data-state="open"],
.context-menu-item[data-highlighted] {
  background-color: var(--bg-color-5);
  color: white;
  border-radius: 0px;
  cursor: pointer;
}
</style>
