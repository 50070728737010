<template>
  <div>
    <div class="measurements-divider" @click="emits('toggle-visibility')">
      Measurements on Selected Clip{{ selectedClipIds.length !== 1 ? "s" : "" }}
      <Chevron :is-dropdown-open="isVisible" />
    </div>

    <Transition @enter="onEnter" @leave="onLeave">
      <OverlayScrollbar
        v-if="isVisible"
        :style="{ flex: measurements.length }"
        :scroll-target="clipCardToScrollTo"
        class="measurement-scrollbar"
      >
        <div class="measurement-cards os-host-flexbox">
          <template v-for="measurement in measurements" :key="measurement.id">
            <MeasurementCard
              v-bind="$attrs"
              :id="`selected-clip-measurement-card-${measurement.id}`"
              :is-expanded="isMeasurementCardExpanded[measurement.id]"
              :measurement="measurement"
              :can-take-measurement="
                measurement !== null &&
                enabledMeasurementTools.includes(getToolNameForMeasurement(measurement)!)
              "
              :study="study"
              :highlighted-measurement-id="highlightedMeasurementId"
              :hovered-measurement-value-id="hoveredMeasurementValueId"
              :reference-range-set-name="referenceRangeSetName"
              :patient-metrics="patientMetrics"
              :visible-frames="visibleFrames"
              :study-update-permitted="studyUpdatePermitted"
              @update:custom-typed-measurement-name="updateCustomMeasurementName($event)"
              @update:measurement-display-option="
                updateMeasurementDisplayOption(measurement.id, $event)
              "
              @take-measurement="takeMeasurement"
              @update:is-expanded="updateMeasurementCardExpanded(measurement.id, $event)"
              @jump-to-value="onJumpToValue"
            />
          </template>
        </div>
      </OverlayScrollbar>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import OverlayScrollbar from "@/components/OverlayScrollbar.vue";
import Chevron from "@/components/ui/chevron/Chevron.vue";
import MeasurementCard from "@/measurements/MeasurementCard.vue";
import { ref } from "vue";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { StudyMeasurementDisplayOption } from "../../../../../backend/src/studies/study-measurement-enums";
import { StudyMeasurement } from "../../../utils/study-data";
import { getToolNameForMeasurement } from "../../measurement-tool-helpers";
import { CommonPaneSectionProps, TabEmits } from "../utils/tabs";

defineProps<
  CommonPaneSectionProps & {
    measurements: StudyMeasurement[];
    isVisible: boolean;
    selectedClipIds: string[];
  }
>();

const emits = defineEmits<
  {
    (event: "toggle-visibility"): void;
    (event: "update:is-measurement-card-expanded", id: string, value: boolean): void;
  } & TabEmits
>();

const clipCardToScrollTo = ref<HTMLElement | null>(null);

function updateMeasurementCardExpanded(id: string, value: boolean) {
  emits("update:is-measurement-card-expanded", id, value);
}

function takeMeasurement(value: { tool: MeasurementToolName; name: MeasurementName }) {
  emits("take-measurement", value);
}

function updateCustomMeasurementName(value: StudyMeasurement) {
  emits("update:custom-typed-measurement-name", value);
}

function updateMeasurementDisplayOption(id: string, value: StudyMeasurementDisplayOption) {
  emits("update:measurement-display-option", id, value);
}

// Set some vars, so we can move this into view like a sort of overlay upside-down accordion.
// This keeps this nicely consistent with the standard accordion behaviour.
function onEnter(el: Element, done: () => void) {
  const height = el.scrollHeight;
  (el as HTMLElement).style.setProperty("--content-height", `${height}px`);
  el.classList.add("slide-down");
  el.addEventListener("animationend", done, { once: true });
}

function onLeave(el: Element, done: () => void) {
  const height = el.scrollHeight;
  (el as HTMLElement).style.setProperty("--content-height", `${height}px`);
  el.classList.add("slide-up");
  el.addEventListener("animationend", done, { once: true });
}
</script>

<style scoped lang="scss">
.measurements-divider {
  background-color: var(--bg-color-3);
  padding: 0 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  border-top: 1px solid var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);

  // Put a shadow on the top edge of the divider to better separate it visually from the
  // scrolling content above
  box-shadow: 0 0 8px 4px rgba(black, 0.5);
  clip-path: inset(-15px 0 0 0);
  z-index: 1;

  svg {
    color: var(--accent-color-1);
    transition: color 100ms ease;
  }

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-3);

    svg {
      color: var(--text-color-2);
    }
  }
}

.measurement-cards {
  display: flex;
  flex-direction: column;
}

.measurement-scrollbar {
  overflow: hidden;
}

.slide-down {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.slide-up {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--content-height);
  }
  to {
    height: 0;
  }
}
</style>
