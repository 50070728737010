<template>
  <div>
    <ContextMenu>
      <ContextMenuTrigger>
        <slot />
      </ContextMenuTrigger>

      <ContextMenuPortal>
        <ContextMenuContent data-testid="study-clip-context-menu">
          <Tooltip :content="downloadTooltip" placement="left">
            <ContextMenuItem
              :disabled="!isDownloadPngEnabled || downloadTooltip !== ''"
              data-testid="download-png"
              @click="onDownloadPngClick"
            >
              <div
                class="context-menu-option"
                :class="{ disabled: !isDownloadPngEnabled || downloadTooltip !== '' }"
              >
                Download PNG
              </div>
            </ContextMenuItem>
          </Tooltip>

          <Tooltip :content="downloadTooltip" placement="left">
            <ContextMenuItem
              :disabled="!isDownloadMp4Enabled || downloadTooltip !== ''"
              @click="onDownloadMp4Click"
            >
              <div
                class="context-menu-option"
                :class="{ disabled: !isDownloadMp4Enabled || downloadTooltip !== '' }"
              >
                Download MP4
              </div>
            </ContextMenuItem>
          </Tooltip>

          <Tooltip :content="downloadDicomTooltip" placement="left">
            <ContextMenuItem
              :disabled="downloadDicomTooltip !== ''"
              data-testid="download-dicom"
              @click="onDownloadDicomClick"
            >
              <div class="context-menu-option" :class="{ disabled: downloadDicomTooltip !== '' }">
                Download DICOM
              </div>
            </ContextMenuItem>
          </Tooltip>

          <Tooltip :content="reprocessClipTooltip" placement="left">
            <ContextMenuItem
              :disabled="reprocessClipTooltip !== ''"
              data-testid="reprocess-clip"
              @click="onReprocessClick"
            >
              <div class="context-menu-option" :class="{ disabled: reprocessClipTooltip !== '' }">
                Reprocess Clip
              </div>
            </ContextMenuItem>
          </Tooltip>

          <div class="divider" />

          <Tooltip :content="sendToDicomEndpointTooltip" placement="left">
            <ContextMenuItem
              :disabled="sendToDicomEndpointTooltip !== ''"
              data-testid="send-dicom"
              @click="onSendToDicomEndpointClick"
            >
              <div
                class="context-menu-option"
                :class="{ disabled: sendToDicomEndpointTooltip !== '' }"
              >
                Send to DICOM Modality
              </div>
            </ContextMenuItem>
          </Tooltip>

          <Tooltip :content="deleteTooltip" placement="left">
            <ContextMenuItem
              :disabled="deleteTooltip !== ''"
              data-testid="delete-clip"
              @click="onDeleteClick"
            >
              <div class="context-menu-option" :class="{ disabled: deleteTooltip !== '' }">
                Delete
              </div>
            </ContextMenuItem>
          </Tooltip>
        </ContextMenuContent>
      </ContextMenuPortal>
    </ContextMenu>
    <template v-if="studyClip !== null">
      <StudyClipDownloadMp4Modal
        v-if="isDownloadClipModalVisible"
        :study="study"
        :clip="studyClip"
        @close="isDownloadClipModalVisible = false"
      />

      <StudyClipDeleteModal
        v-if="isDeleteClipModalVisible"
        :study="study"
        :study-clip="studyClip"
        @deleted="emits('clip-deleted', studyClip)"
        @close="isDeleteClipModalVisible = false"
      />

      <DicomEndpointSendModal
        v-if="isDicomEndpointSendModalVisible && studyClip !== null"
        :study="study"
        :study-clip="studyClip"
        @close="isDicomEndpointSendModalVisible = false"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuPortal,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import axios from "axios";
import { saveAs } from "file-saver";
import { computed, ref } from "vue";
import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import {
  hasDicomEndpointSendPermission,
  hasStudyClipDeletePermission,
  hasStudyUpdatePermission,
  isStudyUpdatePermitted,
} from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import DicomEndpointSendModal from "../components/DicomEndpointSendModal.vue";
import Tooltip from "../components/Tooltip.vue";
import { getStudyClipImageDataUrl } from "../study-view/study-clip-image-data";
import StudyClipDeleteModal from "../study-view/StudyClipDeleteModal.vue";
import StudyClipDownloadMp4Modal from "../study-view/StudyClipDownloadMp4Modal.vue";
import { convertImageUrlToPngDataUrl } from "../utils/image-utils";
import { addNotification } from "../utils/notifications";
import type { Study, StudyClip } from "../utils/study-data";
import { isClipProcessed } from "./study-clip-helpers";

interface Props {
  study: Study;
  clip: StudyClip;
}

interface Emits {
  (event: "clip-deleted", deletedClip: StudyClip): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const studyClip = ref<StudyClip | null>(props.clip);

const isDownloadPngEnabled = computed(() => (studyClip.value?.frameCount ?? 0) === 1);

const isDownloadMp4Enabled = computed(
  () => (studyClip.value?.frameCount ?? 0) > 1 && (studyClip.value?.fps ?? 0) > 0
);

const isDownloadClipModalVisible = ref(false);
const isDeleteClipModalVisible = ref(false);
const isDicomEndpointSendModalVisible = ref(false);

const reprocessClipTooltip = computed(() => {
  if (!studyClip.value || !isClipProcessed(studyClip.value)) {
    return "";
  }

  return "You can only reprocess clips that are stuck processing";
});

const sendToDicomEndpointTooltip = computed(() => {
  if (!currentTenant.hasDicomEndpoints) {
    return "There are no DICOM modalities configured";
  }

  if (!hasDicomEndpointSendPermission.value) {
    return "You do not have permission to send to DICOM modalities";
  }

  if (isFakedSeriesClip.value) {
    return "Sending CT and MR series is not yet supported";
  }

  return "";
});

const downloadTooltip = computed(() => {
  if (isFakedSeriesClip.value) {
    return "Downloading CT and MR series is not yet supported";
  }

  return "";
});

const downloadDicomTooltip = computed(() =>
  studyClip.value?.deidentify === true
    ? "DICOM not available for download due to deidentification"
    : downloadTooltip.value
);

const deleteTooltip = computed(() => {
  if (!hasStudyUpdatePermission.value || !hasStudyClipDeletePermission.value) {
    return "You do not have permission to delete clips";
  }

  if (isFakedSeriesClip.value) {
    return "Deleting CT and MR series is not yet supported";
  }

  if (!isStudyUpdatePermitted(props.study)) {
    return "Cannot delete clips when the report is signed";
  }

  return "";
});

const isFakedSeriesClip = computed(
  () =>
    (studyClip.value?.modality === "MR" || studyClip.value?.modality === "CT") &&
    (studyClip.value.frameCount ?? 0) > 1
);

/**
 * Downloads the :sop-instance-uid.webp-packed file containing a single frame without the header to
 * get the single webP file.
 */
async function extractFrameFromSingleFramePackedWebP(studyClip: StudyClip): Promise<string> {
  if (studyClip.frameCount !== 1) {
    throw Error("Clip frame count must be 1.");
  }

  const url = getStudyClipImageDataUrl(props.study, studyClip, StudyClipImageDataType.AllFrames);
  if (url === null) {
    throw Error("Clip is unavailable for download.");
  }

  // Download the webp-packed file with the header (bytes 0 to 7) stripped to get the single webp
  // file
  const response = await axios.get<ArrayBuffer>(url, {
    responseType: "arraybuffer",
    headers: { Range: "bytes=8-" },
  });

  const blob = new Blob([response.data]);
  return URL.createObjectURL(blob);
}

async function onDownloadPngClick(): Promise<void> {
  if (studyClip.value === null) {
    return;
  }

  try {
    const allFramesBlobUrl = await extractFrameFromSingleFramePackedWebP(studyClip.value);
    const pngDataUri = await convertImageUrlToPngDataUrl(allFramesBlobUrl);

    if (pngDataUri === null) {
      throw Error();
    }

    saveAs(pngDataUri, `HeartLab - ${studyClip.value.sopInstanceUid}.png`);
    addNotification({ type: "info", message: "Downloaded PNG" });
  } catch (error) {
    addNotification({ type: "error", message: "Failed downloading PNG" });
  }
}

async function onReprocessClick(): Promise<void> {
  if (studyClip.value === null) {
    return;
  }

  try {
    await axios.post(`/api/studies/${props.study.id}/${studyClip.value.id}/reprocess`, {
      headers: { "Content-Type": "application/json" },
    });
  } catch {
    addNotification({ type: "error", message: "Failed reprocessing clip" });
  }
}

async function onDownloadDicomClick(): Promise<void> {
  if (studyClip.value === null) {
    return;
  }

  try {
    const url = getStudyClipImageDataUrl(
      props.study,
      studyClip.value,
      StudyClipImageDataType.Dicom
    );

    if (url !== null) {
      const response = await axios.get<Blob>(url, { responseType: "blob" });
      saveAs(response.data, `HeartLab - ${studyClip.value.sopInstanceUid}.dcm`);
    }
  } catch {
    addNotification({ type: "error", message: "Failed downloading DICOM file" });
  }
}

function onDownloadMp4Click(): void {
  isDownloadClipModalVisible.value = true;
}

function onSendToDicomEndpointClick(): void {
  isDicomEndpointSendModalVisible.value = true;
}

function onDeleteClick(): void {
  isDeleteClipModalVisible.value = true;
}
</script>

<style scoped lang="scss">
.context-menu-option {
  transition: color 100ms ease;
  white-space: nowrap;
  display: flex;
  gap: 12px;
  margin: 0 4px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: var(--text-color-2);
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}

.divider {
  border-top: 1px solid var(--accent-color-1);
}
</style>
