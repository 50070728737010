<template>
  <div class="measurement-cards os-host-flexbox" data-testid="measurements-list">
    <template v-for="measurement in measurements" :key="measurement.id">
      <MeasurementCard
        :id="`measurement-card-${measurement.id}`"
        :is-expanded="isMeasurementCardExpanded[measurement.id]"
        :measurement="measurement"
        :can-take-measurement="
          measurement !== null &&
          enabledMeasurementTools.includes(getToolNameForMeasurement(measurement)!)
        "
        :study="study"
        :highlighted-measurement-id="highlightedMeasurementId"
        :hovered-measurement-value-id="hoveredMeasurementValueId"
        :reference-range-set-name="referenceRangeSetName"
        :patient-metrics="patientMetrics"
        :visible-frames="visibleFrames"
        :study-update-permitted="studyUpdatePermitted"
        @update:custom-typed-measurement-name="updateCustomMeasurementName($event)"
        @update:is-expanded="updateMeasurementCardExpanded(measurement.id, $event)"
        @update:measurement-display-option="updateMeasurementDisplayOption(measurement.id, $event)"
        @take-measurement="takeMeasurement"
        @jump-to-value="onJumpToValue"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import MeasurementCard from "@/measurements/MeasurementCard.vue";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { StudyMeasurementDisplayOption } from "../../../../../backend/src/studies/study-measurement-enums";
import { StudyMeasurement } from "../../../utils/study-data";
import { getToolNameForMeasurement } from "../../measurement-tool-helpers";
import { CommonPaneSectionProps, TabEmits } from "../utils/tabs";

defineProps<CommonPaneSectionProps & { measurements: StudyMeasurement[] }>();

const emits = defineEmits<TabEmits>();

function takeMeasurement(value: { tool: MeasurementToolName; name: MeasurementName }) {
  emits("take-measurement", value);
}

function updateCustomMeasurementName(value: StudyMeasurement) {
  emits("update:custom-typed-measurement-name", value);
}

function updateMeasurementCardExpanded(id: string, value: boolean) {
  emits("update:is-measurement-card-expanded", id, value);
}

function updateMeasurementDisplayOption(id: string, value: StudyMeasurementDisplayOption) {
  emits("update:measurement-display-option", id, value);
}
</script>
