export enum RoleType {
  SystemControlledAdmin = "systemControlledAdmin",
  SystemControlledSupport = "systemControlledSupport",
  SystemControlledGuest = "systemControlledGuest",
  TenantControlled = "tenantControlled",
  ApiKey = "apiKey",
}

export enum GlobalAdminRoleType {
  SuperGlobalAdmin = "superAdmin",
  SystemControlledGlobalAdmin = "systemControlledGlobalAdmin",
}
