<template>
  <Popper
    ref="addCustomMeasurementCardPopper"
    :offset-distance="-1"
    :offset-distance-cross-axis="-4"
    :show="isAddCustomMeasurementCardVisible"
    placement="right-start"
    class="add-custom-measurement-card-popper"
    @open="emits('open')"
    @close="emits('close')"
  >
    <IconButton
      icon="plus"
      tooltip="Create custom measurement"
      :type="'regular-rounded'"
      highlight
      data-testid="add-custom-measurement-button"
      :disabled="isAddCustomMeasurementCardVisible"
    />

    <template #content>
      <AddCustomMeasurementCard :study="study" :measurement="measurement" @close="emits('close')" />
    </template>
  </Popper>
</template>

<script setup lang="ts">
import IconButton from "@/components/IconButton.vue";
import Popper from "@/components/Popper.vue";
import AddCustomMeasurementCard from "@/measurements/AddCustomMeasurementCard.vue";
import { Study, StudyMeasurement } from "@/utils/study-data";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

interface Props {
  study: Study;
  isAddCustomMeasurementCardVisible: boolean;
  measurement?: StudyMeasurement;
}

const props = defineProps<Props>();

const emits = defineEmits<{ (event: "close"): void; (event: "open"): void }>();

const addCustomMeasurementCardPopper = ref<HTMLElement | null>(null);

onClickOutside(addCustomMeasurementCardPopper, () => {
  if (props.isAddCustomMeasurementCardVisible) {
    emits("close");
  }
});
</script>
