<template>
  <ContextMenuTrigger class="context-menu-trigger" :class="props.class" v-bind="forwardedProps">
    <slot />
  </ContextMenuTrigger>
</template>
<script setup lang="ts">
import { ContextMenuTrigger, ContextMenuTriggerProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuTriggerProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = usePropsExcludingClass(props);
</script>
