<template>
  <ContextMenuSubTrigger
    v-bind="forwardedProps"
    class="context-menu-sub-trigger"
    :class="[{ disabled: props.disabled }, props.class]"
  >
    <slot />
  </ContextMenuSubTrigger>
</template>
<script setup lang="ts">
import { type ContextMenuSubTriggerProps, ContextMenuSubTrigger } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuSubTriggerProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = usePropsExcludingClass(props);
</script>
<style scoped lang="scss">
.context-menu-sub-trigger {
  font-size: 13px;
  line-height: 1;
  color: white;
  background-color: var(--bg-color-6);
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
  user-select: none;
  outline: none;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.context-menu-sub-trigger[data-state="open"],
.context-menu-sub-trigger[data-highlighted] {
  background-color: var(--bg-color-5);
  color: white;
}
</style>
