<template>
  <Popper
    placement="bottom-end"
    :interactive="true"
    :offset-distance="2"
    @open="
      isDropdownOpen = true;
      downloadStore.isNewDownload = false;
      $emit('open');
    "
    @close="isDropdownOpen = false"
  >
    <button
      data-testid="download-popper-button"
      class="dropdown-button"
      :class="{ active: isDropdownOpen, open: isDropdownOpen }"
    >
      <FontAwesomeIcon
        icon="cloud-arrow-down"
        data-testid="download-popper-icon"
        :bounce="downloadStore.isNewDownload"
      />

      <div class="text-content">Downloads ({{ downloadStore.numberOfDownloads }})</div>

      <Chevron :is-dropdown-open="chevronState" />
    </button>

    <template #content>
      <div data-testid="download-popper-content" style="max-height: 384px; overflow-y: auto">
        <div
          v-for="download in downloadStore.studies"
          :key="download.study.id"
          class="download-item-container"
        >
          <div :data-testid="`download-item-${download.study.id}`" class="download-item-content">
            <div style="display: flex; flex-direction: column; gap: 8px">
              <div :data-testid="`download-item-text-${download.study.id}`">
                <LoadingIndicator
                  v-if="download.getDownloadState() === DownloadStatus.Downloading"
                  style="margin-right: 5px"
                  :data-testid="`download-item-loading-indicator-${download.study.id}`"
                />

                <FontAwesomeIcon
                  v-else-if="download.getDownloadState() === DownloadStatus.Downloaded"
                  icon="check"
                  style="margin-right: 5px"
                  :data-testid="`download-item-check-icon-${download.study.id}`"
                />

                <b>
                  {{ `${download.getTextForDownloadStatus()} ` }}
                </b>

                <u
                  v-if="download.getDownloadState() === DownloadStatus.Downloading"
                  style="cursor: pointer"
                  :data-testid="`download-item-cancel-button-${download.study.id}`"
                  @click="downloadStore.removeStudy(download.study.id)"
                >
                  Cancel
                </u>
              </div>
              {{
                `${download.study.patientId} ${formatDateTime(download.study.createdAt, { includeTime: true })}`
              }}
            </div>
            <div style="margin: 0 0 0 16px">
              <CircularProgress
                v-if="download.getDownloadState() === DownloadStatus.Downloading"
                :progress-percentage="
                  (download.downloadProgress.reduce((a, b) => a + b, 0) /
                    (1 + download.study.clipsCount)) *
                  100
                "
              />

              <button
                v-else-if="
                  download.getDownloadState() === DownloadStatus.Ready ||
                  download.getDownloadState() === DownloadStatus.Downloaded
                "
                :data-testid="`download-item-save-button-${download.study.id}`"
                :class="{ accented: download.getDownloadState() === DownloadStatus.Ready }"
                @click="downloadStore.saveDicomZipFile(download.study.id)"
              >
                <FontAwesomeIcon icon="cloud-arrow-down" />
                Save ZIP file
              </button>
              <button
                v-else
                :data-testid="`download-item-open-button-${download.study.id}`"
                :class="{ accented: download.getDownloadState() === DownloadStatus.Downloaded }"
                disabled
              >
                <FontAwesomeIcon icon="cloud-arrow-down" />
                Failed
              </button>
            </div>
          </div>
        </div>
        <div v-if="downloadStore.studies.length > 0" class="download-item-container-footer">
          <div style="padding-right: 16px">
            {{ downloadStore.studies.length }}
            {{ downloadStore.studies.length > 1 ? "downloads" : "download" }}
          </div>

          <button
            data-testid="download-popper-clear-button"
            @click="downloadStore.clearDownloads()"
          >
            Clear
          </button>
        </div>
        <div
          v-else
          style="padding: 4px; overflow: hidden"
          data-testid="download-popper-no-downloads"
        >
          No downloads
        </div>
      </div>
    </template>
  </Popper>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useEventListener } from "@vueuse/core";
import { computed, ref } from "vue";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { DownloadStatus, useDownloadStore } from "../utils/study-download-store";
import CircularProgress from "./CircularProgress.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import Popper from "./Popper.vue";
import Chevron from "./ui/chevron/Chevron.vue";

interface Emits {
  (event: "open"): void;
}

defineEmits<Emits>();
const isDropdownOpen = ref(false);

const downloadStore = useDownloadStore();
const chevronState = computed(() => {
  if (downloadStore.numberOfDownloads === 0) {
    return false;
  }
  return isDropdownOpen.value;
});

function onBeforeUnload(event: Event) {
  if (
    downloadStore.studies.length > 0 &&
    downloadStore.studies.some(
      (download) => download.getDownloadState() === DownloadStatus.Downloading
    )
  ) {
    event.preventDefault();
  }
}

useEventListener(window, "beforeunload", onBeforeUnload);
</script>
<style scoped lang="scss">
.dropdown-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
}

.download-item-container {
  display: flex;
  border: 1px solid #434343;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #1e1e1e;
  height: 64px;
  width: 384px;
  min-width: 160px;
}

.download-item-container-footer {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  margin-top: 8px;
}

.download-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 20 1;
  padding: 8px;
}
</style>
