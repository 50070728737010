<template>
  <Modal title="Saved Share Link Recipients" @header-button-click="emits('close')">
    <OverlayScrollbar style="max-height: 40vh">
      <div class="recipients-grid">
        <b>Name</b>
        <b>Email</b>
        <div />

        <template v-for="recipient in recipients" :key="recipient.id">
          <div class="name">{{ recipient.name }}</div>
          <div>{{ recipient.email }}</div>
          <div>
            <Tooltip content="Remove recipient">
              <FontAwesomeIcon
                icon="trash"
                class="delete-icon"
                @click="emits('remove-recipient', recipient.id)"
              />
            </Tooltip>
          </div>
        </template>
      </div>
    </OverlayScrollbar>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { StudyShareLinkRecipientGetManyResponseDto } from "../../../backend/src/studies/sharing/dto/study-share-link-recipient-get-many.dto";
import OverlayScrollbar from "./OverlayScrollbar.vue";

interface Props {
  studyId: string;
  recipients: StudyShareLinkRecipientGetManyResponseDto;
}

interface Emits {
  (event: "remove-recipient", recipientId: string): void;
  (event: "close"): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.recipients-grid {
  display: grid;
  grid-template-columns: min-content 1fr auto;
  gap: 12px;
  padding: 8px;
}

.name {
  white-space: nowrap;
}

.delete-icon {
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
