<template>
  <ContextMenuContent class="context-menu-content" :class="props.class" v-bind="forwardedProps">
    <slot />
  </ContextMenuContent>
</template>

<script setup lang="ts">
import { ContextMenuContent, useForwardProps, type ContextMenuContentProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuContentProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>

<style scoped lang="scss">
:deep(.context-menu-content) {
  border: solid 1px var(--border-color-2);
  border-radius: 4px;
  min-width: 220px;
  background-color: var(--bg-color-6);
  overflow: hidden;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);

  animation: grow-in 100ms ease;
  transform-origin: var(--radix-context-menu-content-transform-origin);
  z-index: 100;
}

@keyframes grow-in {
  from {
    opacity: 0;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
