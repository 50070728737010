<template>
  <template v-for="group of permissionGroups" :key="group.name">
    <Checkbox
      :model-value="
        !group.permissions.some((permission) => !role?.permissions.includes(permission.value))
      "
      :enabled="isEditable"
      :data-testid="`toggle-all-${group.name}`"
      @update:model-value="emits('permission-group-toggle', $event, group)"
    >
      {{ group.name }}
    </Checkbox>

    <div>{{ group.description }}</div>

    <template v-if="group.permissions.length > 1">
      <template v-for="permission in group.permissions" :key="permission.value">
        <div class="permission">
          <Checkbox
            :model-value="role.permissions.includes(permission.value)"
            :data-testid="`permission-toggle-${permission.value}`"
            :enabled="isEditable"
            @update:model-value="emits('permission-toggle', $event, permission)"
          >
            {{ permission.name }}
          </Checkbox>
        </div>

        <div>{{ permission.description }}</div>
      </template>
    </template>
  </template>
</template>

<script
  setup
  lang="ts"
  generic="
    P extends Permission | GlobalAdminPermission,
    I extends PermissionInfoBase<P>,
    G extends PermissionGroupBase<I>
  "
>
import Checkbox from "@/components/Checkbox.vue";
import { toRefs } from "vue";
import {
  GlobalAdminPermission,
  Permission,
} from "../../../../backend/src/auth/permissions/permissions";
import { GlobalAdminRoleType, RoleType } from "../../../../backend/src/user/roles/user-role-type";
import { PermissionGroupBase, PermissionInfoBase } from "../permission-toggle";

interface Props {
  permissionGroups: G[];
  role: {
    permissions: P[];
    type: GlobalAdminRoleType | RoleType;
  };
  isEditable?: boolean;
}

interface Emits {
  (event: "permission-group-toggle", value: boolean, group: G): void;
  (event: "permission-toggle", value: boolean, permission: I): void;
}

const emits = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), { isEditable: false });

const { role } = toRefs(props);
</script>
