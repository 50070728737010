<template>
  <ContextMenuPortal :class="props.class" v-bind="forwardedProps">
    <slot />
  </ContextMenuPortal>
</template>

<script setup lang="ts">
import { ContextMenuPortal, useForwardProps, type ContextMenuPortalProps } from "radix-vue";
import { type HTMLAttributes } from "vue";
import { usePropsExcludingClass } from "../ui-utils";

const props = defineProps<ContextMenuPortalProps & { class?: HTMLAttributes["class"] }>();
const forwardedProps = useForwardProps(usePropsExcludingClass(props));
</script>
