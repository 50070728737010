<template>
  <Modal
    title="Contact Support"
    :activity-text="activityText"
    @header-button-click="emits('close')"
  >
    <div class="contact-support-modal">
      <div class="modal-footer">
        Before submitting a support request, please review our help documentation at
        <a href="https://help.heartlab.com" target="_blank" class="link">help.heartlab.com</a>
      </div>
      <div class="field">
        <strong>Contact Email</strong>
        <Tooltip
          style="display: flex"
          content="HeartLab support will reply to this email address"
          placement="bottom-end"
        >
          <input
            ref="emailInputElement"
            v-model="email"
            type="text"
            style="flex: 1"
            data-testid="email-input"
          />
        </Tooltip>
      </div>

      <div class="field">
        <strong>Subject</strong>
        <input
          ref="subjectInputElement"
          v-model="subject"
          type="text"
          data-testid="subject-input"
        />
      </div>

      <div class="field">
        <strong>Priority</strong>
        <DropdownWidget
          v-model="priority"
          :items="priorities"
          placeholder="Select priority"
          data-testid="priority-selection"
        />
      </div>

      <div class="field">
        <strong>Message</strong>
        <textarea
          v-model="message"
          placeholder="Please describe the issue you are experiencing"
          data-testid="message-input"
        />
      </div>

      <div v-if="priority === '1' || priority === '2'" class="field">
        <span>
          <b>Important:</b> Urgent and high priority support cases are for situations where there is
          an immediate and significant risk to patient safety, or a vital clinical workflow is not
          functioning and an immediate resolution is required.
        </span>
        <Checkbox
          v-model="isPrioritySupportCaseConfirmed"
          style="align-self: center; margin-top: 8px"
        >
          I confirm that this support request is {{ priority === "1" ? "urgent" : "high priority" }}
        </Checkbox>
      </div>

      <button
        class="accented"
        :disabled="!isComplete"
        style="width: max-content; margin: 4px auto 0"
        data-testid="send-button"
        @click="sendSupportTicket"
      >
        Send Message
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import { currentTenant, currentUser } from "../auth/current-session";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";
import Checkbox from "./Checkbox.vue";
import DropdownWidget from "./DropdownWidget.vue";
import Modal from "./Modal.vue";

interface Emits {
  (event: "close"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const email = ref(currentUser.email);
const subject = ref("");
const message = ref("");
const priority = ref("");

const isPrioritySupportCaseConfirmed = ref(false);

const priorities = computed(() => {
  const result = [
    { value: "3", text: "Medium" },
    { value: "4", text: "Low" },
    { value: "5", text: "Lowest" },
  ];

  if (currentTenant.isHighPrioritySupportCaseCreationAllowed) {
    result.splice(0, 0, { value: "1", text: "Urgent" }, { value: "2", text: "High" });
  }

  return result;
});

const subjectInputElement = ref<HTMLInputElement | null>(null);
useFocus(subjectInputElement, { initialValue: true });

const isComplete = computed(
  () =>
    subject.value.trim() !== "" &&
    message.value.trim() !== "" &&
    email.value.trim() !== "" &&
    priority.value.trim() !== "" &&
    ((priority.value !== "1" && priority.value !== "2") || isPrioritySupportCaseConfirmed.value)
);

async function sendSupportTicket(): Promise<void> {
  activityText.value = "Sending";

  try {
    await axios.post("/api/tenants/support-ticket", {
      email: email.value.trim(),
      subject: subject.value.trim(),
      priority: Number(priority.value),
      message: message.value.trim(),
      currentUrl: window.location.href,
    });
  } catch {
    addNotification({
      type: "error",
      message: "Failed submitting support request, please email help@heartlab.com instead",
    });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Support request submitted" });

  emits("close");
}
</script>

<style scoped lang="scss">
.contact-support-modal {
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  textarea {
    height: 120px;
    line-height: 1.4em;
    resize: none;
  }
}

.modal-footer {
  text-align: center;
  margin: 0.2em 0 0.5em;

  .link {
    color: var(--accent-color-1);
  }
}
</style>
